import React, {useState} from "react";
// @material-ui/core components
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import componentStyles from "assets/theme/views/auth/login.js";
import componentStylesButtons from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

function Login() {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const handleLogin = () => {
   
    const data = {
      email: email,
      password: password
    };




    fetch("https://subgrants.safetyforvoices.org:3535/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
        
        localStorage.setItem('token',data.token)
        history.push('/admin/response');
       
      })
      .catch((error) => {
        alert("Invalid Credentials")
        console.error("Error making POST request:", error);
      });
  };


  const handleEmail = (event) => { 
    setEmail(event.target.value);
  };

  const handlePassword = (event) => { 
    setPassword(event.target.value);
  };

  const theme = useTheme();
  return (
    <>
      <AuthHeader
        title="Welcome!"
        description="To APC Control Panel"
      />
      {/* Page content */}
      <Container
        component={Box}
        maxWidth="xl"
        marginTop="-8rem"
        paddingBottom="3rem"
        position="relative"
        zIndex="101"
      >
        <Box component={Grid} container justifyContent="center">
          <Grid item xs={12} lg={5} md={7}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                className={classes.cardHeader}
                title={
                  <Box
                    fontSize="80%"
                    fontWeight="400"
                    component="small"
                    color={theme.palette.gray[600]}
                  >
                    LOGIN IN
                  </Box>
                }
                titleTypographyProps={{
                  component: Box,
                  textAlign: "center",
                  marginBottom: "1rem!important",
                  marginTop: ".5rem!important",
                  fontSize: "1rem!important",
                }}
                
              ></CardHeader>
              <CardContent classes={{ root: classes.cardContent }}>
                <Box
                  color={theme.palette.gray[600]}
                  textAlign="center"
                  marginBottom="1rem"
                  marginTop=".5rem"
                  fontSize="1rem"
                >
                  <Box fontSize="80%" fontWeight="400" component="small">
                    sign in with credentials
                  </Box>
                </Box>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <FilledInput
                    autoComplete="off"
                    type="email"
                    placeholder="Email"
                    startAdornment={
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    }
                    onChange={handleEmail}
                  />
                </FormControl>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <FilledInput
                    autoComplete="off"
                    type="password"
                    placeholder="Password"
                    startAdornment={
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                       
                    }
                    onChange={handlePassword}
                  />

                </FormControl>
                <FormControlLabel
                  value="end"
                  control={<Checkbox color="primary" />}
                  label="Remember me"
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.formControlLabelLabel,
                  }}
                />
                <Box
                  textAlign="center"
                  marginTop="1.5rem"
                  marginBottom="1.5rem"
                >
                  <Button
                    variant="contained"
                    classes={{ root: classes.buttonContainedInfo }}
                    onClick={handleLogin}
                  >
                    Sign in
                  </Button>
                </Box>
              </CardContent>
            </Card>
            <Grid container component={Box} marginTop="1rem">
              <Grid item xs={6} component={Box} textAlign="left">
                
              </Grid>
              <Grid item xs={6} component={Box} textAlign="right">
               
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Login;
