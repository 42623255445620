import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
//import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import AFRICA from "../Sortable/africa";
import AMERICA from "../Sortable/america";
import ASIA from "../Sortable/asia";
import MENA from "../Sortable/mena";
// @material-ui/icons components

// core components

import componentStyles from "assets/theme/components/cards/dashboard/card-social-traffic.js";

const useStyles = makeStyles(componentStyles);

function CardSocialTraffic() {
  const [Total, setTotal] = React.useState(0);
  const [Africa, setAfrica] = React.useState(0);
  const [Mena, setMena] = React.useState(0);
  const [Asia, setAsia] = React.useState(0);
  const [America, setAmerica] = React.useState(0);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://subgrants.safetyforvoices.org:3535/api/responses"
        );
        const data = await response.json();
        setData(data);

        setTotal(data.length)

        let count = data.filter((item) => AFRICA.includes(item.country)).length;
        setAfrica(count);

        count = data.filter((item) => AMERICA.includes(item.country)).length;
        setAmerica(count);

        count = data.filter((item) => MENA.includes(item.country)).length;
        setMena(count);

        count = data.filter((item) => ASIA.includes(item.country)).length;
        setAsia(count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);
  const classes = useStyles();
  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  Regional Responses
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box
                  justifyContent="flex-end"
                  display="flex"
                  flexWrap="wrap"
                ></Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  Region
                </TableCell>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  Responses
                </TableCell>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  AFRICA
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {Africa}
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  <Box display="flex" alignItems="center">
                    <Box component="span" marginRight=".5rem">
                      {((Africa/Total)*100).toFixed(0)} %
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={((Africa/Total)*100).toFixed(0)}
                      classes={{
                        root: classes.linearProgressRoot,
                        bar: classes.bgGradientError,
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  MENA
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {Mena}
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  <Box display="flex" alignItems="center">
                    <Box component="span" marginRight=".5rem">
                     {((Mena/Total)*100).toFixed(0)}%
                    </Box>
                    <Box width="100%">
                      <LinearProgress
                        variant="determinate"
                        value={((Mena/Total)*100).toFixed(0)}
                        classes={{
                          root: classes.linearProgressRoot,
                          bar: classes.bgGradientSuccess,
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  LATIN AMERICA
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {America}
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  <Box display="flex" alignItems="center">
                    <Box component="span" marginRight=".5rem">
                      {((America/Total)*100).toFixed(0)}%
                    </Box>
                    <Box width="100%">
                      <LinearProgress
                        variant="determinate"
                        value={((America/Total)*100).toFixed(0)}
                        classes={{
                          root: classes.linearProgressRoot,
                          bar: classes.bgGradientPrimary,
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  ASIA & PACIFIC
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {Asia}
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  <Box display="flex" alignItems="center">
                    <Box component="span" marginRight=".5rem">
                      {((Asia/Total)*100).toFixed(0)}%
                    </Box>
                    <Box width="100%">
                      <LinearProgress
                        variant="determinate"
                        value={((Asia/Total)*100).toFixed(0)}
                        classes={{
                          root: classes.linearProgressRoot,
                          bar: classes.bgGradientInfo,
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Box>
        </TableContainer>
      </Card>
    </>
  );
}

export default CardSocialTraffic;
