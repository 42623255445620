
const asiaPacific = [
    "Afghanistan", "Azerbaijan", "Bangladesh", "Bhutan", "Brunei", "Cambodia", "China", 
    "Fiji", "India", "Indonesia", "Iran", "Isl Rep Kazakhstan", "Kiribati", "Kyrgyzstan", 
    "Lao, PDR", "Malaysia", "Maldives", "Micronesia, Fed States of", "Mongolia", "Myanmar", 
    "Nepal", "Pakistan", "Palau", "Papua New Guinea", "Philippines", "Samoa", 
    "Solomon Islands", "Sri Lanka", "Tajikistan", "Thailand", "Timor-Leste", "Tonga", 
    "Turkey", "Turkmenistan", "Tuvalu", "Uzbekistan", "Vanuatu", "Vietnam"
];

export default asiaPacific;
