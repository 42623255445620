import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components

// core components
import StatsHeader from "components/Headers/StatsHeader.js";
//import CardSalesValue from "components/Cards/Dashboard/CardSalesValue.js";
//import CardTotalOrders from "components/Cards/Charts/CardTotalOrders.js";
//import CardTeamMembers from "components/Cards/Dashboard/CardTeamMembers.js";
//import CardToDoList from "components/Cards/Dashboard/CardToDoList.js";
//import CardProgressTrack from "components/Cards/Dashboard/CardProgressTrack.js";
//import CardActivityFeed from "components/Cards/Dashboard/CardActivityFeed.js";
//import CardLightTable from "components/Cards/Dashboard/CardLightTable.js";
//import CardCreditDetails from "components/Cards/Dashboard/CardCreditDetails.js";
//import CardCredit from "components/Cards/Dashboard/CardCredit.js";
//import CardPageVisits from "components/Cards/Dashboard/CardPageVisits.js";
import CardSocialTraffic from "components/Cards/Dashboard/CardSocialTraffic.js";
import CardStatus from "components/Cards/Dashboard/EligibleTractCard";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import CardAffiliateTraffic from "components/Cards/Charts/CardAffiliateTraffic"
import Whrds from "components/Cards/Charts/Whrds"

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

function Dashboard() {
  const classes = { ...useStyles(), ...useStylesCardDeck() };
  
  const history = useHistory();

  React.useEffect(() => {
    fetch("https://subgrants.safetyforvoices.org:3535/api/validate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
      })
      .catch((error) => {
        history.push("/");
        console.error("Error making POST request:", error);
      });

    
  }, []);
  return (
    <>
      <StatsHeader section="Stats" subsection="Dashboard" />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          
          
          <Grid item xs={12} xl={6}>
            <CardSocialTraffic />
          </Grid>
          <Box style={{width:"20px"}}>

          </Box>
          <Grid item xs={12} xl={5}>
            <CardStatus />
          </Grid>

        </Grid>
      </Container>


      <Container
        maxWidth={false}
        component={Box}
        marginTop="1rem"
        classes={{ root: classes.containerRoot }}
      >
      <Grid container>
          
          <Grid item xs={12} xl={6}>
            <CardAffiliateTraffic />
          </Grid>

          <Grid item xs={12} xl={6}>
            <Whrds />
          </Grid>

        </Grid>
      </Container>


      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          
          
         
          

        </Grid>
      </Container>
     
    </>
  );
}

export default Dashboard;
