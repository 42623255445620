import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// @material-ui/core components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
} from "@material-ui/core"; // Importing components from Material-UI

import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
//import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import CardHeader from "@material-ui/core/CardHeader";
//import LinearProgress from "@material-ui/core/LinearProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { useHistory } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import InputBase from "@material-ui/core/InputBase";
import AFRICA from "./africa";
import ASIA from "./asia";
import MENA from "./mena";
import AMERICA from "./america";
//import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/lab components
//import AvatarGroup from "@material-ui/lab/AvatarGroup";
// @material-ui/icons components
import MoreVert from "@material-ui/icons/MoreVert";
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";

const useStyles = makeStyles(componentStyles);

const tableHead = [
  "No.",
  "ID",
  "Created_at",
  "Project title",
  "Country",
  "oragnisation",
  "Reflected areas",
  "Select",
  "Status",
  "",
];

const DropdownComponent = ({ data, id, filename, privilege }) => {
  const history = useHistory();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const DownloadFile = () => {
    const token = localStorage.getItem("token"); // Assuming you have stored the token in localStorage

    const downloadFile = async () => {
      try {
        const response = await fetch(
          "https://subgrants.safetyforvoices.org:3535/api/download-file",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ filename: filename, token: token }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to download file");
        }

        const blob = await response.blob();

        // Create a temporary URL for the file
        const url = window.URL.createObjectURL(blob);

        // Create a link element and simulate a click to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file:", error);
        // Handle error
      }
    };

    downloadFile();
  };

  const ViewMore = (event) => {
    localStorage.setItem("selectedResponse", JSON.stringify(data[id]));
    window.open("/#/admin/user-response");
  };

  const ViewEval = (event) => {
    localStorage.setItem("selectedResponseID", JSON.stringify(data[id].id));
    window.open("/#/admin/view-evaluation");
  };

  const MarkEligible = () => {
    fetch("https://subgrants.safetyforvoices.org:3535/api/update-status", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        id: data[id].id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        aria-controls={"simple-menu-" + id}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        component={Button}
        width="2rem!important"
        height="2rem!important"
        minWidth="2rem!important"
        minHeight="2rem!important"
      >
        <Box
          component={MoreVert}
          width="1.25rem!important"
          height="1.25rem!important"
          position="relative"
          top="2px"
          color={theme.palette.gray[500]}
        />
      </Box>
      <Menu
        id={"simple-menu-" + id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { data[id].assigned_to === null && (
        <MenuItem onClick={ViewMore}>View More</MenuItem>
        )}
        <MenuItem onClick={DownloadFile}>Download Budget File</MenuItem>
      </Menu>
    </>
  );
};

export default function CardLightTableSortable() {
  const history = useHistory();
  const classes = useStyles();
  const [selectedResponse, setSelectedResponse] = React.useState([]);
  const [privilege, setPrivilege] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [adminEmails, setAdminEmails] = React.useState([]);
  const [selectedEmail, setSelectedEmail] = React.useState("");
  const [data, setData] = React.useState([
    {
      id: 0,
      formatted_created_at: "data loading",
      question10: "data loading",
      country: "data loading",
      organisation: "data loading",
      question11: "data loading",
      status: "loading",
    },

    // Add more test data objects as needed
  ]);
  const [open, setOpen] = React.useState(false); // State to control the visibility of the dialog
  const [checked, setChecked] = React.useState(false); // State to store the checkbox state

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailSelect = (event) => {
    setSelectedEmail(event.target.value); // Update selected email
  };

  const [tempdata, setTempData] = React.useState([
    {
      id: 0,
      formatted_created_at: "data loading",
      question10: "data loading",
      country: "data loading",
      organisation: "data loading",
      question11: "data loading",
      status: "data loading",
    },

    // Add more test data objects as needed
  ]);

  const defaultData = [
    {
      id: 0,
      formatted_created_at: "data loading",
      question10: "data loading",
      country: "data loading",
      organisation: "data loading",
      question11: "data loading",
      status: "data loading",
    },
    // Add more test data objects as needed
  ];

  const resetData = () => {
    setData(defaultData);
  };

  const IdChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const CountryChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const OrgChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const ReflectedChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleReflected = (event) => {
    if (event.key === "Enter") {
      const filtered =
        searchQuery !== null
          ? tempdata.filter(
              (item) =>
                item.question11 &&
                item.question11.toString().includes(searchQuery)
            )
          : data;

      setData(filtered);
    }
  };

  const handleIdSearch = (event) => {
    if (event.key === "Enter") {
      const filtered = tempdata.filter((item) =>
        item.id.toString().includes(searchQuery)
      );
      setData(filtered);
    }

    
  };

  const handleCountrySearch = (event) => {
    if (event.key === "Enter") {
      const filtered =
        searchQuery !== null
          ? tempdata.filter(
              (item) =>
                item.country &&
                item.country
                  .toString()
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
            )
          : data;

      setData(filtered);
    }
  };

  const handleOrgSearch = (event) => {
    if (event.key === "Enter") {
      const filtered =
        searchQuery !== null
          ? tempdata.filter(
              (item) =>
                item.organisation &&
                item.organisation
                  .toString()
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
            )
          : data;

      setData(filtered);
    }
  };


  const handleAfricaSearch = () => {
    const filtered =
      searchQuery !== null
        ? tempdata.filter((item) => {
            // Check if the country name is included in the africa array
            return AFRICA.includes(item.country);
          })
        : data;

    setData(filtered);
  };

  const handleAsiaSearch = () => {
    const filtered =
      searchQuery !== null
        ? tempdata.filter((item) => {
            // Check if the country name is included in the africa array
            return ASIA.includes(item.country);
          })
        : data;

    setData(filtered);
  };

  const handleMenaSearch = () => {
    const filtered =
      searchQuery !== null
        ? tempdata.filter((item) => {
            // Check if the country name is included in the africa array
            return MENA.includes(item.country);
          })
        : data;

    setData(filtered);
  };

  const handleAmericaSearch = () => {
    const filtered =
      searchQuery !== null
        ? tempdata.filter((item) => {
            // Check if the country name is included in the africa array
            return AMERICA.includes(item.country);
          })
        : data;

    setData(filtered);
  };

  const handleNeligibleSearch = () => {
    const filtered =
      searchQuery !== null
        ? tempdata.filter((item) => {
            // Check if the status includes "negligible"
            return item.status.includes("negligible");
          })
        : tempdata;

    setData(filtered);
  };

  const handleEligibleSearch = () => {
    const filtered =
      searchQuery !== null
        ? tempdata.filter((item) => {
            // Check if the status includes "negligible"
            return item.status.includes("eligible");
          })
        : tempdata;

    setData(filtered);
  };

  const HandleViewAll = () => {
    setData(defaultData);
    setData(tempdata);
  };

  const handleAssign = () => {
    if (selectedResponse.length === 0) {
      alert("No response is selected");
      return; // If selectedResponse array is empty, exit the function
    }

    fetch("https://subgrants.safetyforvoices.org:3535/api/admin-emails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const flattenedArray = data.flat();
        setAdminEmails(flattenedArray);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });

    setOpen(true);
  };

  const handleProceedAssigning = () => {
    fetch("https://subgrants.safetyforvoices.org:3535/api/update-Assigned_to", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: selectedEmail, ids: selectedResponse }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const handleCheckbox = (key) => (event) => {
    const id = data[key].id;
    if (event.target.checked) {
      setSelectedResponse((prevIds) => [...prevIds, id]);
    } else {
      setSelectedResponse((prevIds) =>
        prevIds.filter((prevId) => prevId !== id)
      );
    }
  };

  const tableConatinerRef = React.useRef(null);
  React.useEffect(() => {
    fetch("https://subgrants.safetyforvoices.org:3535/api/validate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
      })
      .catch((error) => {
        history.push("/");
        console.error("Error making POST request:", error);
      });

    fetch("https://subgrants.safetyforvoices.org:3535/api/privilege", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setPrivilege(data[0].privilege);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });

      const fetchData = async () => {
        try {
          const response = await fetch(
            "https://subgrants.safetyforvoices.org:3535/api/long-responses"
          );
          const data = await response.json();
          setData(data);
          setTempData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

    // Call the fetchData function when the component mounts
    fetchData();
    new List(tableConatinerRef.current, {
      valueNames: ["name", "budget", "status", "completion"],
      listClass: "list",
    });
  }, []);
  return (
    <>
      <Card classes={{ root: classes.cardRoot }} style={{ display: "grid" }}>
        <CardHeader
          className={classes.cardHeader}
          title="Pre-Evaluation"
          titleTypographyProps={{
            component: Box,
            marginBottom: "0!important",
            variant: "h3",
          }}
        ></CardHeader>
        <Box
          display="flow-root"
          alignItems="center"
          style={{
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingBottom: "10px",
            alignItems: "stretch",
          }}
        >
          <InputBase
            placeholder="Search by ID"
            classes={{
              input: classes.searchInput,
            }}
            style={{ marginLeft: "10px", marginBottom: "10px" }}
            onChange={IdChange}
            onKeyDown={handleIdSearch}
          />
          <InputBase
            placeholder="Search by Country"
            classes={{
              input: classes.searchInput,
            }}
            style={{ marginLeft: "10px", marginBottom: "10px" }}
            onChange={CountryChange}
            onKeyPress={(event) => handleCountrySearch(event)}
          />
          <InputBase
            placeholder="Search by Organisation"
            classes={{
              input: classes.searchInput,
            }}
            onKeyPress={(event) => handleOrgSearch(event)}
            onChange={OrgChange}
            style={{ marginLeft: "10px", marginBottom: "10px" }}
          />

          <InputBase
            placeholder="Search by Reflected Area"
            classes={{
              input: classes.searchInput,
            }}
            onKeyPress={(event) => handleReflected(event)}
            onChange={ReflectedChange}
            style={{
              marginLeft: "10px",
              marginRight: "20px",
              marginBottom: "10px",
            }}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          style={{
            marginLeft: "30px",
            alignItems: "stretch",
            marginBottom: "15px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            className={classes.buttonRoot}
            onClick={HandleViewAll}
          >
            View All
          </Button>

          <Button
            variant="contained"
            size="small"
            className={classes.buttonRoot}
            onClick={handleNeligibleSearch}
            style={{ marginLeft: "8px" }}
          >
            Ineligible
          </Button>

          <Button
            variant="contained"
            size="small"
            className={classes.buttonRoot}
            onClick={handleEligibleSearch}
            style={{ marginLeft: "8px" }}
          >
            Eligible
          </Button>
        </Box>

        <Box
          component={Divider}
          //marginTop="2rem!important"
          //marginBottom="2rem!important"
          style={{ height: "0.6px", backgroundColor: "lightgray" }}
        />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "15px", alignItems: "stretch" }}
        >

          <Box>

          
          <FormLabel style={{ marginTop: "8px", alignContent: "center" }}>
            SORT BY REGIONS:{" "}
          </FormLabel>
         
          <Button
            variant="contained"
            size="small"
            className={classes.buttonRoot}
            onClick={handleAfricaSearch}
            style={{ marginLeft: "8px" }}
          >
            AFRICA
          </Button>

          <Button
            variant="contained"
            size="small"
            className={classes.buttonRoot}
            onClick={handleAsiaSearch}
            style={{ marginLeft: "8px" }}
          >
            ASIA & THE PACIFIC
          </Button>

          <Button
            variant="contained"
            size="small"
            className={classes.buttonRoot}
            onClick={handleMenaSearch}
            style={{ marginLeft: "8px" }}
          >
            MENA
          </Button>

          <Button
            variant="contained"
            size="small"
            className={classes.buttonRoot}
            onClick={handleAmericaSearch}
            style={{ marginLeft: "8px" }}
          >
            LATIN AMERICA
          </Button>

          </Box>
         
         <Box>
          {privilege !== "user" &&
            privilege !== "evaluator" &&
            privilege !== "financial evaluator" && (
              <Button
                variant="contained"
                size="small"
                className={classes.buttonRoot}
                onClick={handleAssign}
                style={{  }}
              >
                Assign Task
              </Button>
            )}

</Box>
           
        </Box>
        <TableContainer
          ref={tableConatinerRef}
          style={{
            maxHeight: "calc(65vh - 100px)",
            maxWidth: "100%",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead style={{ position: "sticky", top: 0, zIndex: 100 }}>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    style={{ backgroundColor: "lightgray", color: "gray" }}
                    key={key}
                    {...{
                      "data-sort":
                        prop !== "" && prop !== "Users"
                          ? (prop === "Project" ? "name" : prop).toLowerCase()
                          : undefined,
                    }}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
              {data.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize=".875rem"
                          component="span"
                          className="name"
                        >
                          {key + 1}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    {prop.id}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    {prop.formatted_created_at}
                  </TableCell>

                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    {prop.question10}
                  </TableCell>

                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    {prop.country}
                  </TableCell>

                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    {prop.organisation && prop.organisation.length > 25
                      ? prop.organisation.substring(0, 25) + "..."
                      : prop.organisation}
                  </TableCell>

                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    {prop.question11}
                  </TableCell>

                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    {prop.assigned_to === null ? (
                      <FormControlLabel
                        value="start"
                        control={
                          <Checkbox
                            color="red"
                            onChange={handleCheckbox(key)}
                          />
                        }
                        label=""
                        labelPlacement="end"
                        style={{ marginLeft: "3px" }}
                        classes={{
                          root: classes.formControlCheckboxLabelRoot,
                          label: classes.formControlCheckboxLabelLabel,
                        }}
                      />
                    ) : (
                      <div>
                        {prop.assigned_to !== "Evaluated" && (
                          <div>
                          <div>assigned to</div>
                          <div>{prop.assigned_to}</div>
                          </div>
                        )}
                        
                        {prop.assigned_to === "Evaluated" && (
                          <div>
                           {prop.assigned_to}
                          </div>
                        )}
                      </div>
                    )}
                  </TableCell>

                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                    style={{
                      color:
                        prop.status === "negligible"
                          ? "red"
                          : prop.status === "eligible"
                          ? "green"
                          : "inherit",
                    }}
                  >
                    {prop.status}
                  </TableCell>

                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    align="right"
                  >
                    <DropdownComponent
                      privilege={privilege}
                      data={data}
                      id={key}
                      filename={prop.filename}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
        </TableContainer>
      </Card>

      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>SELECT THE EMAIL YOU WANT TO ASSIGN TASK TO</DialogTitle>
          <DialogContent>
            <RadioGroup value={selectedEmail} onChange={handleEmailSelect}>
              {adminEmails.map((admin) => (
                <FormControlLabel
                  key={admin.id}
                  value={admin.email}
                  control={<Radio />}
                  label={admin.email}
                />
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleProceedAssigning} color="primary">
              Proceed
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
