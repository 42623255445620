import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
//import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import FormLabel from "@material-ui/core/FormLabel";
import CardHeader from "@material-ui/core/CardHeader";
//import StatsHeader from "components/Headers/SimpleHeader";
//import LinearProgress from "@material-ui/core/LinearProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { useHistory } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import FormGroup from "@material-ui/core/FormGroup";
import Email from "@material-ui/icons/Email";
import Visibility from "@material-ui/icons/Visibility";
import OutlinedInput from "@material-ui/core/OutlinedInput";
//import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";

//import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/lab components
//import AvatarGroup from "@material-ui/lab/AvatarGroup";
// @material-ui/icons components
import MoreVert from "@material-ui/icons/MoreVert";
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";

const useStyles = makeStyles(componentStyles);

const tableHead = ["ID", "email", "privilege","Reset Button","Delete Button" ,""];

const DropdownComponent = ({ data, id }) => {
  const history = useHistory();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const ViewMore = (event) => {
    localStorage.setItem("selectedResponse", JSON.stringify(data[id]));
    window.open("/#/admin/user-response");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        aria-controls={"simple-menu-" + id}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        component={Button}
        width="2rem!important"
        height="2rem!important"
        minWidth="2rem!important"
        minHeight="2rem!important"
      >
        <Box
          component={MoreVert}
          width="1.25rem!important"
          height="1.25rem!important"
          position="relative"
          top="2px"
          color={theme.palette.gray[500]}
        />
      </Box>
      <Menu
        id={"simple-menu-" + id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
       
      </Menu>
    </>
  );
};

export default function CardLightTableSortable() {
  const history = useHistory();
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [privilege, setPrivilege] = React.useState("");
  const [showContent, setShowContent] = React.useState(false);
  const [showContent2, setShowContent2] = React.useState(false);
  const [data, setData] = React.useState([
    {
      id: 0,
      email: "data loading",
    },

    // Add more test data objects as needed
  ]);

  const [tempdata, setTempData] = React.useState([
    {
      id: 0,
      email: "data loading",
    },

    // Add more test data objects as needed
  ]);

  const defaultData = [
    {
      id: 0,
      email: "data loading",
    },
    // Add more test data objects as needed
  ];

  const resetData = () => {
    setData(defaultData);
  };

  const handleOpen = () => {
    setShowContent(!showContent);
  };

  const handleOpen2 = (data,id) => {
    setEmail(data[id].email)
    setShowContent2(!showContent2);
  };

  
  const handleDelete = (data,id) => {
    fetch("https://subgrants.safetyforvoices.org:3535/api/delete-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        id: data[id].id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };



  const ResetPassword = () => {
    fetch("https://subgrants.safetyforvoices.org:3535/api/reset-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        email: email,
        password: password,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.error);
          });
        }
        return response.json();
      })
      .then((data) => {
        alert("Password Changed successfully!");
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const NewUser = () => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].email === email) {
        alert("The email is already taken.");
        return;
      }
    }

    fetch("https://subgrants.safetyforvoices.org:3535/api/create-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        email: email,
        password: password,
        privilege: privilege,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.error);
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log("User created successfully:", data);
        alert("User created successfully!");
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const tableConatinerRef = React.useRef(null);
  React.useEffect(() => {
    fetch("https://subgrants.safetyforvoices.org:3535/api/validate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
      })
      .catch((error) => {
        history.push("/");
        console.error("Error making POST request:", error);
      });

    const fetchData = async () => {
      try {
        // Assuming you have a token stored in a variable named 'token'
        const token = "YOUR_TOKEN_HERE";

        const response = await fetch(
          "https://subgrants.safetyforvoices.org:3535/api/get-users",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Assuming your API expects the token to be sent in the 'Authorization' header
            },
            body: JSON.stringify({ token: localStorage.getItem("token") }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setData(data);
        setTempData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
    new List(tableConatinerRef.current, {
      valueNames: ["name", "budget", "status", "completion"],
      listClass: "list",
    });
  }, []);
  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  List of Users
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.buttonRoot}
                    onClick={handleOpen}
                  >
                    {showContent ? "Close" : "Create New User"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>

        {showContent && (
          <CardContent>
            <FormGroup style={{ width: "50%" }}>
              <OutlinedInput
                fullWidth
                type="email"
                placeholder="Email address"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start" style={{ width: "50px" }}>
                    <Email />
                  </InputAdornment>
                }
              />

              <OutlinedInput
                fullWidth
                type="text"
                placeholder="Password"
                style={{ marginTop: "1rem" }}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Visibility />
                  </InputAdornment>
                }
              />
              <Box style={{ marginTop: "10px" }}>
                <FormLabel>Select the privilege</FormLabel>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    defaultValue="none"
                    IconComponent={KeyboardArrowDown}
                    onChange={(event) => {
                      setPrivilege(event.target.value);
                    }}
                  >
                    <MenuItem value="admin"> Admin</MenuItem>
                    <MenuItem value="super">Super admin</MenuItem>
                    <MenuItem value="user">User </MenuItem>
                    <MenuItem value="evaluator">evaluator </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </FormGroup>

            <Button variant="contained" color="primary" onClick={NewUser}>
              Create
            </Button>
          </CardContent>
        )}

        <TableContainer
          ref={tableConatinerRef}
          style={{
            paddingBottom: "20px",
            maxHeight: "calc(70vh - 100px)",
            maxWidth: "100%",
            overflowY: "auto",
            overflowX: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead style={{ position: "sticky", top: 0, zIndex: 100 }}>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    style={{ backgroundColor: "lightgray", color: "gray" }}
                    key={key}
                    {...{
                      "data-sort":
                        prop !== "" && prop !== "Users"
                          ? (prop === "Project" ? "name" : prop).toLowerCase()
                          : undefined,
                    }}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
              {data.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize=".875rem"
                          component="span"
                          className="name"
                        >
                          {prop.id}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    {prop.email}
                  </TableCell>

                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    {prop.privilege}
                  </TableCell>


                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                     <Button
                    variant="contained"
                    size="small"
                    className={classes.buttonRoot}
                    onClick={() => handleOpen2(data,key)}
                  >
                    Reset Password
                  </Button>
                  </TableCell>


                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                     <Button
                    variant="contained"
                    size="small"
                    className={classes.buttonRoot}
                    onClick={() => handleDelete(data,key)}
                  >
                    Delete User
                  </Button>
                  </TableCell>


                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    align="right"
                  >
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
        </TableContainer>

       
          

          {showContent2 && (
            <CardContent>
              <FormGroup style={{ width: "50%" }}>
                <OutlinedInput
                  fullWidth
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  startAdornment={
                    <InputAdornment position="start" style={{ width: "50px" }}>
                      <Email />
                    </InputAdornment>
                  }
                />

                <OutlinedInput
                  fullWidth

                  type="text"
                  placeholder="New Password"
                  style={{ marginTop: "1rem" }}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Visibility />
                    </InputAdornment>
                  }
                />
              </FormGroup>

              <Button
                variant="contained"
                color="primary"
                onClick={ResetPassword}
              >
                Reset
              </Button>
            </CardContent>
          )}
        
      </Card>
    </>
  );
}
