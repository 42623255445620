import React from "react";
import { useHistory } from "react-router-dom";
//import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import CardContent from "@material-ui/core/CardContent";
//import InputAdornment from "@material-ui/core/InputAdornment";
import CardHeader from "@material-ui/core/CardHeader";
//import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
//import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
//import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

//import Person from "@material-ui/icons/Person";
import Slider from "@material-ui/core/Slider";
import componentStyles from "assets/theme/views/admin/validation.js";
import componentStylesForms from "assets/theme/components/forms.js";
import componentStyles2 from "assets/theme/views/admin/components.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesSwitch from "assets/theme/components/switch.js";
//import { Typography } from "@material-ui/core";

const useStyles2 = makeStyles(componentStyles2);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesSwitch = makeStyles(componentStylesSwitch);

const useStyles = makeStyles(componentStyles);
const useStylesForms = makeStyles(componentStylesForms);



const Validation = () => {

  const initialResponse = {
    id: "loading",
    grantnumber: "loading",
    name: "loading",
    phone: "loading",
    email: "loading",
    country: "loading",
    organisation: "loading",
    question1:"loading",
    question2:"loading",
    question3: "loading",
    question7: "loading",
    question8: "loading",
    question9: "loading",
    question10: "loading",
    question11: "loading",
    question12: "loading",
    question13: "loading",
    question14: "loading",
    question15: "loading",
    question16a: "loading",
    question16b: "loading",
    question18: "loading",
    question19: "loading",
    question20: "loading",
    question21: "loading",
    question22: "loading",
    question23a: "loading",
    question23b: "loading",
    question23c: "loading",
    question23d: "loading",
    question23e: "loading",
    question23f: "loading",
    question23g: "loading",
    question23h: "loading",
    filename: "loading",
    filename2: "loading",
    created_at: "loading",
    formatted_created_at: "loading",
  };

  const history = useHistory();
  const classes = {
    ...useStyles(),
    ...useStylesForms(),
    ...useStyles2(),
    ...useStylesButtons(),
    ...useStylesSwitch(),
  };
  const theme = useTheme();
  const [id, setId] = React.useState("");
  const [question11, setQuestion11] = React.useState("");
  const [question13, setQuestion13] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [summary, setSummary] = React.useState("");
  const [fund, setFund] = React.useState("");
  const [question1, setquestion1] = React.useState("");
  const [question2, setquestion2] = React.useState("");
  const [question3, setquestion3] = React.useState("");
  const [question9, setquestion9] = React.useState("");
  const [question10, setquestion10] = React.useState("");
  const [verdict, setverdict] = React.useState("");
  const [status, setstatus] = React.useState("");
  const [financial, setfinancial] = React.useState("");

  const [box2, setbox2] = React.useState("");
  const [box3, setbox3] = React.useState("");
  const [box4, setbox4] = React.useState("");
  const [box5, setbox5] = React.useState("");
  const [box6, setbox6] = React.useState("");
  const [box7, setbox7] = React.useState("");
  const [box9, setbox9] = React.useState("");

  const [sliderSingle, setSliderSingle] = React.useState(0 && selectedEval && selectedEval[0] && selectedEval[0].sliderSingle);
  const [sliderSingle5, setSliderSingle5] = React.useState(0 && selectedEval && selectedEval[0] && selectedEval[0].sliderSingle5);
  const [sliderSingle6, setSliderSingle6] = React.useState(0 && selectedEval && selectedEval[0] && selectedEval[0].sliderSingle6);
  const [sliderSingle7, setSliderSingle7] = React.useState(0 && selectedEval && selectedEval[0] && selectedEval[0].sliderSingle7);
  const [sliderSingle8, setSliderSingle8] = React.useState(0 && selectedEval && selectedEval[0] && selectedEval[0].sliderSingle8);

  const [showContent, setShowContent] = React.useState(false);
  const [showContent1, setShowContent1] = React.useState(false);
  const [showContent3, setShowContent3] = React.useState(false);
  const [showContent4, setShowContent4] = React.useState(false);

  const [selectedEval, setSelectedEval] = React.useState(initialResponse);


  const handleButtonClick = () => {
    setShowContent(!showContent);
  };

  const handleButtonClick1 = () => {
    setShowContent1(!showContent1);
  };

  const handleButtonClick3 = () => {
    setShowContent3(!showContent3);
  };

  const handleButtonClick4 = () => {
    setShowContent4(!showContent4);
  };

  const SubmitFinancial = () => {
    if (financial === "") {
      alert("Compulsary Fields are not filled");
      return;
    }

   
    fetch("https://subgrants.safetyforvoices.org:3535/api/FinancialEvalCheck", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        id,
        financial
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
        window.close();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const LongSubmit = () => {
    if (summary === "" || verdict === "") {
      alert("Compulsary Fields are not filled");
      return;
    }

    
    fetch("https://subgrants.safetyforvoices.org:3535/api/SendtoLongList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        id,
        summary,
        verdict,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
        window.close();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const Submit = () => {
    if (
      fund === "" ||
      question1 === "" ||
      question2 === "" ||
      question3 === "" ||
      sliderSingle === 0 ||
      sliderSingle5 === 0 ||
      sliderSingle6 === 0 ||
      sliderSingle7 === 0 ||
      sliderSingle8 === 0
    ) {
      alert("Compulsary Fields are not filled");
      return;
    }

    fetch("https://subgrants.safetyforvoices.org:3535/api/updateEvaluation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        id,
        question10,
        question11,
        question13,
        country,
        fund,
        question1,
        question2,
        question3,
        question9,
        box2,
        box3,
        box4,
        box5,
        box6,
        box7,
        box9,
        sliderSingle,
        sliderSingle5,
        sliderSingle6,
        sliderSingle7,
        sliderSingle8,
        question10,
        verdict,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
        window.close();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };
  


  const ReviewSubmit = () => {
    if (
      fund === "" ||
      question1 === "" ||
      question2 === "" ||
      question3 === "" ||
      sliderSingle === 0 ||
      sliderSingle5 === 0 ||
      sliderSingle6 === 0 ||
      sliderSingle7 === 0 ||
      sliderSingle8 === 0
    ) {
      alert("Compulsary Fields are not filled");
      return;
    }

    fetch("https://subgrants.safetyforvoices.org:3535/api/insertEvaluation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("token"),
        id,
        question10,
        question11,
        question13,
        country,
        fund,
        question1,
        question2,
        question3,
        question9,
        box2,
        box3,
        box4,
        box5,
        box6,
        box7,
        box9,
        sliderSingle,
        sliderSingle5,
        sliderSingle6,
        sliderSingle7,
        sliderSingle8,
        question10,
        verdict,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
        window.close();
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };
  

// Access the id

  React.useEffect(() => {
    const storedResponse = localStorage.getItem("selectedResponse");

// Parse the JSON string to an object
const responseObject = JSON.parse(storedResponse);
    fetch("https://subgrants.safetyforvoices.org:3535/api/validate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
      })
      .catch((error) => {
        history.push("/");
        console.error("Error making POST request:", error);
      });

    const storedArray = localStorage.getItem("EvaluationResponse");
    if (storedArray) {
      const parsedArray = JSON.parse(storedArray);
      setId(parsedArray[0]);
      setQuestion11(parsedArray[1]);
      setQuestion13(parsedArray[2]);
      setCountry(parsedArray[3]);
      setstatus(parsedArray[4]);
      setfinancial(parsedArray[5]); 
    }

    fetch(
      "https://subgrants.safetyforvoices.org:3535/api/view-evaluationForm",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: responseObject.id,
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
       
        setSelectedEval(data);
        setSliderSingle(data && data[0] && data[0].sliderSingle)
        setSliderSingle5(data && data[0] && data[0].sliderSingle5)
        setSliderSingle6(data && data[0] && data[0].sliderSingle6)
        setSliderSingle7(data && data[0] && data[0].sliderSingle7)
        setSliderSingle8(data && data[0] && data[0].sliderSingle8)
        setFund(data && data[0] && data[0].fund)
        setquestion1(data && data[0] && data[0].question1)
        setquestion2(data && data[0] && data[0].question2)
        setquestion3(data && data[0] && data[0].question3)
        setquestion9(data && data[0] && data[0].question9)
        setquestion10(data && data[0] && data[0].question10)
        setbox2(data && data[0] && data[0].box2)
        setbox3(data && data[0] && data[0].box3)
        setbox4(data && data[0] && data[0].box4)
        setbox6(data && data[0] && data[0].box6)
        setbox7(data && data[0] && data[0].box7)
        setbox9(data && data[0] && data[0].box9)
        setbox5(data && data[0] && data[0].box5)
        
        // Process the response data here
      })
      .catch((error) => {
        alert("Error making POST request:", error);
      });
  }, []);

  return (
    <>
      <SimpleHeader section="Evaluation" subsection="Forms" />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="20px"
        classes={{ root: classes.containerRoot }}
      >
        {/* Table */}
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="SECTION 1"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={handleButtonClick1}
              >
                {showContent1 ? "Hide" : "Show"} Content
              </Button>
            }
          ></CardHeader>
          {showContent1 && (
            <CardContent>
              <FormGroup>
                <FormLabel>1) Project Summary</FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  marginBottom="10px"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br /> Brief project summary
                  <br />
                  If you suggest rejection, please inform the reason
                </Box>
                <OutlinedInput
                  fullWidth
                  value={selectedEval && selectedEval[0] && selectedEval[0].summary}
                  multiline
                  rows="7"
                  onChange={(event) => setSummary(event.target.value)}
                />
              </FormGroup>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  Please select the status of this application from the options
                  below
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  onChange={(event) => setverdict(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Waiting"
                    value="waiting"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Send to long list"
                    labelPlacement="end"
                    value="long"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Rejected"
                    labelPlacement="end"
                    value="rejected"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                </RadioGroup>
              </Box>

              {status === "eligible" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={LongSubmit}
                >
                  Send to Long List
                </Button>
              )}
            </CardContent>
          )}
        </Card>

        <Card classes={{ root: classes.cardRoot }}></Card>
      </Container>

      <Container
        maxWidth={false}
        component={Box}
        marginTop="30px"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="SECTION 2"
            marginTop="100px"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
              >
                {showContent ? "Hide" : "Show"} Content
              </Button>
            }
          ></CardHeader>

          {showContent && (
            <CardContent>
              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  2) Country / Region :{" "}
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br />
                  {country}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  3) Alignment to one or more three programme objectives :
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br />
                  {question13}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  4) Type of grant :
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br />
                  {question11}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  5) Has this applicant has received funds already?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  value={fund }
                  onChange={(event) => setFund(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="Yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    value="No"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="I dont know"
                    labelPlacement="end"
                    value="I dont Know"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                </RadioGroup>
              </Box>
              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  1) Do you know the references provided?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  value=  {question1}
                  name="example-radio"
                  onChange={(event) => setquestion1(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="Yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    value="No"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  2) Is the project focused in land and environmental issues?
                </FormLabel>
                <RadioGroup
                  value= {question2}
                  aria-label="gender"
                  name="example-radio"
                  onChange={(event) => setquestion2(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="Yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    value="No"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      multiline
                      value= {box2}
                      onChange={(event) => setbox2(event.target.value)}
                      rows="3"
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  3) Is the project focused on WHRDs safety?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  value={question3}
                  name="example-radio"
                  onChange={(event) => setquestion3(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="Yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    value="No"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      value= {box3}
                      multiline
                      rows="3"
                      onChange={(event) => setbox3(event.target.value)}
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  4) Priority communities and intersectionality
                </FormLabel>

                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br />
                  Reviewer to reflect on expressed intersectionality,
                  marginalised voices ie lgbtqi+, indigenous, black and brown
                  WHRDs, ppl who live with disabilities.
                  <br />
                  Please make sure to consider is implementing and who is the
                  target population
                  <br /> Insert 1 to 5 button rating
                </Box>

                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  defaultValue="2"
                  value={sliderSingle}
                  className={classes.mb0}
                >
                  <Box display="flex" marginTop="10px" marginBottom="10px">
                    <Slider
                      style={{
                        minWidth: "250px",
                        maxWidth: "500px",
                        marginRight: "50px",
                      }}
                      min={0}
                      max={5.0}
                      step={1}
                      value={sliderSingle }
                      onChange={(event, value) => setSliderSingle(value)}
                    />
                    <Grid
                      container
                      component={Box}
                      marginTop="1px"
                      marginBottom="5px"
                      marginLeft="10px"
                    >
                      <Grid item xs={6}>
                        <Box
                          component="span"
                          fontSize=".75rem"
                          fontWeight="500"
                          color="#FFFFFF"
                          borderRadius="2px"
                          padding=".4em .8em .3em .85em"
                          className={classes.sliderBadge}
                        >
                          {sliderSingle}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      value={selectedEval && selectedEval[0] && selectedEval[0].box4}
                      multiline
                      rows="3"
                      onChange={(event) => setbox4(event.target.value)}
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  5) How effectively was the technological approach formulated
                  and integrated into the project?
                </FormLabel>

                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br /> Insert 1 to 5 button rating
                </Box>

                <RadioGroup
                  aria-label="gender"
                  value={sliderSingle5}
                  name="example-radio"
                  defaultValue="2"
                  className={classes.mb0}
                >
                  <Box display="flex" marginTop="10px" marginBottom="10px">
                    <Slider
                      style={{
                        minWidth: "250px",
                        maxWidth: "500px",
                        marginRight: "50px",
                      }}
                      min={0}
                      max={5.0}
                      step={1}
                      value={sliderSingle5}
                      onChange={(event, value) => setSliderSingle5(value)}
                    />
                    <Grid
                      container
                      component={Box}
                      marginTop="1px"
                      marginBottom="5px"
                      marginLeft="10px"
                    >
                      <Grid item xs={6}>
                        <Box
                          component="span"
                          fontSize=".75rem"
                          fontWeight="500"
                          color="#FFFFFF"
                          borderRadius="2px"
                          padding=".4em .8em .3em .85em"
                          className={classes.sliderBadge}
                        >
                          {sliderSingle5}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      value={box5}
                      multiline
                      rows="3"
                      onChange={(event) => setbox5(event.target.value)}
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  6) Feasibility –To what extent does the application
                  demonstrate a clear, feasible, practical and sustainable idea?
                  Are the activities proposed achievable? Is the proposed budget
                  realistic, efficient and practical ?
                </FormLabel>

                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br />
                  Please consider if the measures proposed correspond to the
                  risk faced by WHRDs
                  <br /> Insert 1 to 5 button rating
                </Box>

                <RadioGroup
                  aria-label="gender"
                  value={sliderSingle6}
                  name="example-radio"
                  defaultValue="2"
                  className={classes.mb0}
                >
                  <Box display="flex" marginTop="10px" marginBottom="10px">
                    <Slider
                      style={{
                        minWidth: "250px",
                        maxWidth: "500px",
                        marginRight: "50px",
                      }}
                      min={0}
                      max={5.0}
                      step={1}
                      value={sliderSingle6}
                      onChange={(event, value) => setSliderSingle6(value)}
                    />
                    <Grid
                      container
                      component={Box}
                      marginTop="1px"
                      marginBottom="5px"
                      marginLeft="10px"
                    >
                      <Grid item xs={6}>
                        <Box
                          component="span"
                          fontSize=".75rem"
                          fontWeight="500"
                          color="#FFFFFF"
                          borderRadius="2px"
                          padding=".4em .8em .3em .85em"
                          className={classes.sliderBadge}
                        >
                          {sliderSingle6}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      value= {box6}
                      multiline
                      onChange={(event) => setbox6(event.target.value)}
                      rows="3"
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  7) Impact – Is this project likely to contribute to improve
                  the security of the organisation? What is the expected impact
                  for the community?
                </FormLabel>

                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br /> Insert 1 to 5 button rating
                </Box>

                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  value={sliderSingle7}
                  defaultValue="2"
                  className={classes.mb0}
                >
                  <Box display="flex" marginTop="10px" marginBottom="10px">
                    <Slider
                      style={{
                        minWidth: "250px",
                        maxWidth: "500px",
                        marginRight: "50px",
                      }}
                      min={0}
                      max={5.0}
                      step={1}
                      value={sliderSingle7}
                      onChange={(event, value) => setSliderSingle7(value)}
                    />
                    <Grid
                      container
                      component={Box}
                      marginTop="1px"
                      marginBottom="5px"
                      marginLeft="10px"
                    >
                      <Grid item xs={6}>
                        <Box
                          component="span"
                          fontSize=".75rem"
                          fontWeight="500"
                          color="#FFFFFF"
                          borderRadius="2px"
                          padding=".4em .8em .3em .85em"
                          className={classes.sliderBadge}
                        >
                          {sliderSingle7}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <FormGroup>
                    <OutlinedInput
                    value={box7}
                      fullWidth
                      multiline
                      onChange={(event) => setbox7(event.target.value)}
                      rows="3"
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  8) Experience - Does the applicant have demonstrated
                  experience in implementing similar projects focused on WHRDs
                  protection?
                </FormLabel>

                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br /> Insert 1 to 5 button rating
                </Box>

                <RadioGroup
                  aria-label="gender"
                  value={sliderSingle8}
                  name="example-radio"
                  defaultValue="2"
                  className={classes.mb0}
                >
                  <Box display="flex" marginTop="10px">
                    <Slider
                      style={{
                        minWidth: "250px",
                        maxWidth: "500px",
                        marginRight: "50px",
                      }}
                      min={0}
                      max={5.0}
                      step={1}
                      value={sliderSingle8}
                      onChange={(event, value) => setSliderSingle8(value)}
                    />
                    <Grid
                      container
                      component={Box}
                      marginTop="1px"
                      marginBottom="5px"
                      marginLeft="10px"
                    >
                      <Grid item xs={6}>
                        <Box
                          component="span"
                          fontSize=".75rem"
                          fontWeight="500"
                          color="#FFFFFF"
                          borderRadius="2px"
                          padding=".4em .8em .3em .85em"
                          className={classes.sliderBadge}
                        >
                          {sliderSingle8}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  9) Do you recommend this project for approval?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  value={question9}
                  name="example-radio"
                  onChange={(event) => setquestion9(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="Yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    value="No"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Maybe"
                    labelPlacement="end"
                    value="Maybe"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      value= {box9}
                      multiline
                      onChange={(event) => setbox9(event.target.value)}
                      rows="3"
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <FormLabel
                style={{
                  marginTop: "2px",
                  marginRight: "4px",
                  lineHeight: "18px",
                }}
              >
                10) Please insert any qualitative feedback on the quality of
                application and recommendations for improvement.
              </FormLabel>

              <OutlinedInput
                fullWidth
                multiline
                value= {question10}
                rows="7"
                marginBottom="10px"
                onChange={(event) => setquestion10(event.target.value)}
              />

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  Please select the status of this application from the options
                  below
                </FormLabel>
                <RadioGroup
                  aria-label="gender"

                  name="example-radio"
                  onChange={(event) => setverdict(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Waiting"
                    value="waiting"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Send to short list"
                    labelPlacement="end"
                    value="short"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Rejected"
                    labelPlacement="end"
                    value="rejected"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                </RadioGroup>
              </Box>

              {status === "long" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={Submit}
                >
                  Send to Short List
                </Button>
              )}

             
            </CardContent>
          )}
        </Card>
      </Container>

      <Container
        maxWidth={false}
        component={Box}
        marginTop="60px"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="SECTION 3"
            marginTop="100px"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={handleButtonClick4}
              >
                {showContent4 ? "Hide" : "Show"} Content
              </Button>
            }
          ></CardHeader>

          {showContent4 && (
            <CardContent>
              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  2) Country / Region :{" "}
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br />
                  {country}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  3) Alignment to one or more three programme objectives :
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br />
                  {question13}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  4) Type of grant :
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br />
                  {question11}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  5) Has this applicant has received funds already?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  onChange={(event) => setFund(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="Yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    value="No"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="I dont know"
                    labelPlacement="end"
                    value="I dont Know"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                </RadioGroup>
              </Box>
              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  1) Do you know the references provided?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  onChange={(event) => setquestion1(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="Yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    value="No"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  2) Is the project focused in land and environmental issues?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  onChange={(event) => setquestion2(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="Yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    value="No"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      multiline
                      onChange={(event) => setbox2(event.target.value)}
                      rows="3"
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  3) Is the project focused on WHRDs safety?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  onChange={(event) => setquestion3(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="Yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    value="No"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      multiline
                      rows="3"
                      onChange={(event) => setbox3(event.target.value)}
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  4) Priority communities and intersectionality
                </FormLabel>

                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br />
                  Reviewer to reflect on expressed intersectionality,
                  marginalised voices ie lgbtqi+, indigenous, black and brown
                  WHRDs, ppl who live with disabilities.
                  <br />
                  Please make sure to consider is implementing and who is the
                  target population
                  <br /> Insert 1 to 5 button rating
                </Box>

                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  defaultValue="2"
                  className={classes.mb0}
                >
                  <Box display="flex" marginTop="10px" marginBottom="10px">
                    <Slider
                      style={{
                        minWidth: "250px",
                        maxWidth: "500px",
                        marginRight: "50px",
                      }}
                      min={0}
                      max={5.0}
                      step={1}
                      value={sliderSingle}
                      onChange={(event, value) => setSliderSingle(value)}
                    />
                    <Grid
                      container
                      component={Box}
                      marginTop="1px"
                      marginBottom="5px"
                      marginLeft="10px"
                    >
                      <Grid item xs={6}>
                        <Box
                          component="span"
                          fontSize=".75rem"
                          fontWeight="500"
                          color="#FFFFFF"
                          borderRadius="2px"
                          padding=".4em .8em .3em .85em"
                          className={classes.sliderBadge}
                        >
                          {sliderSingle}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                 

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      multiline
                      rows="3"
                      onChange={(event) => setbox4(event.target.value)}
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  5) How effectively was the technological approach formulated
                  and integrated into the project?
                </FormLabel>

                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br /> Insert 1 to 5 button rating
                </Box>

                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  defaultValue="2"
                  className={classes.mb0}
                >
                  <Box display="flex" marginTop="10px" marginBottom="10px">
                    <Slider
                      style={{
                        minWidth: "250px",
                        maxWidth: "500px",
                        marginRight: "50px",
                      }}
                      min={0}
                      max={5.0}
                      step={1}
                      value={sliderSingle5}
                      onChange={(event, value) => setSliderSingle5(value)}
                    />
                    <Grid
                      container
                      component={Box}
                      marginTop="1px"
                      marginBottom="5px"
                      marginLeft="10px"
                    >
                      <Grid item xs={6}>
                        <Box
                          component="span"
                          fontSize=".75rem"
                          fontWeight="500"
                          color="#FFFFFF"
                          borderRadius="2px"
                          padding=".4em .8em .3em .85em"
                          className={classes.sliderBadge}
                        >
                          {sliderSingle5}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      multiline
                      rows="3"
                      onChange={(event) => setbox5(event.target.value)}
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  6) Feasibility –To what extent does the application
                  demonstrate a clear, feasible, practical and sustainable idea?
                  Are the activities proposed achievable? Is the proposed budget
                  realistic, efficient and practical ?
                </FormLabel>

                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br />
                  Please consider if the measures proposed correspond to the
                  risk faced by WHRDs
                  <br /> Insert 1 to 5 button rating
                </Box>

                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  defaultValue="2"
                  className={classes.mb0}
                >
                  <Box display="flex" marginTop="10px" marginBottom="10px">
                    <Slider
                      style={{
                        minWidth: "250px",
                        maxWidth: "500px",
                        marginRight: "50px",
                      }}
                      min={0}
                      max={5.0}
                      step={1}
                      value={sliderSingle6}
                      onChange={(event, value) => setSliderSingle6(value)}
                    />
                    <Grid
                      container
                      component={Box}
                      marginTop="1px"
                      marginBottom="5px"
                      marginLeft="10px"
                    >
                      <Grid item xs={6}>
                        <Box
                          component="span"
                          fontSize=".75rem"
                          fontWeight="500"
                          color="#FFFFFF"
                          borderRadius="2px"
                          padding=".4em .8em .3em .85em"
                          className={classes.sliderBadge}
                        >
                          {sliderSingle6}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      multiline
                      onChange={(event) => setbox6(event.target.value)}
                      rows="3"
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  7) Impact – Is this project likely to contribute to improve
                  the security of the organisation? What is the expected impact
                  for the community?
                </FormLabel>

                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br /> Insert 1 to 5 button rating
                </Box>

                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  defaultValue="2"
                  className={classes.mb0}
                >
                  <Box display="flex" marginTop="10px" marginBottom="10px">
                    <Slider
                      style={{
                        minWidth: "250px",
                        maxWidth: "500px",
                        marginRight: "50px",
                      }}
                      min={0}
                      max={5.0}
                      step={1}
                      value={sliderSingle7}
                      onChange={(event, value) => setSliderSingle7(value)}
                    />
                    <Grid
                      container
                      component={Box}
                      marginTop="1px"
                      marginBottom="5px"
                      marginLeft="10px"
                    >
                      <Grid item xs={6}>
                        <Box
                          component="span"
                          fontSize=".75rem"
                          fontWeight="500"
                          color="#FFFFFF"
                          borderRadius="2px"
                          padding=".4em .8em .3em .85em"
                          className={classes.sliderBadge}
                        >
                          {sliderSingle7}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      multiline
                      onChange={(event) => setbox7(event.target.value)}
                      rows="3"
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  8) Experience - Does the applicant have demonstrated
                  experience in implementing similar projects focused on WHRDs
                  protection?
                </FormLabel>

                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  <br /> Insert 1 to 5 button rating
                </Box>

                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  defaultValue="2"
                  className={classes.mb0}
                >
                  <Box display="flex" marginTop="10px">
                    <Slider
                      style={{
                        minWidth: "250px",
                        maxWidth: "500px",
                        marginRight: "50px",
                      }}
                      min={0}
                      max={5.0}
                      step={1}
                      value={sliderSingle8}
                      onChange={(event, value) => setSliderSingle8(value)}
                    />
                    <Grid
                      container
                      component={Box}
                      marginTop="1px"
                      marginBottom="5px"
                      marginLeft="10px"
                    >
                      <Grid item xs={6}>
                        <Box
                          component="span"
                          fontSize=".75rem"
                          fontWeight="500"
                          color="#FFFFFF"
                          borderRadius="2px"
                          padding=".4em .8em .3em .85em"
                          className={classes.sliderBadge}
                        >
                          {sliderSingle8}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </RadioGroup>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  9) Do you recommend this project for approval?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  onChange={(event) => setquestion9(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="Yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    value="No"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Maybe"
                    labelPlacement="end"
                    value="Maybe"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormGroup>
                    <OutlinedInput
                      fullWidth
                      multiline
                      onChange={(event) => setbox9(event.target.value)}
                      rows="3"
                      placeholder="Comment box"
                    />
                  </FormGroup>
                </RadioGroup>
              </Box>

              <FormLabel
                style={{
                  marginTop: "2px",
                  marginRight: "4px",
                  lineHeight: "18px",
                }}
              >
                10) Please insert any qualitative feedback on the quality of
                application and recommendations for improvement.
              </FormLabel>

              <OutlinedInput
                fullWidth
                multiline
                rows="7"
                marginBottom="10px"
                onChange={(event) => setquestion10(event.target.value)}
              />

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  Please select the status of this application from the options
                  below
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="example-radio"
                  onChange={(event) => setverdict(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Waiting"
                    value="waiting"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Send to short list"
                    labelPlacement="end"
                    value="short"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />

                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Rejected"
                    labelPlacement="end"
                    value="rejected"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                </RadioGroup>
              </Box>

              {status === "short" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={ReviewSubmit}
                >
                  SUBMIT REVIEW
                </Button>
              )}
            </CardContent>
          )}
        </Card>
      </Container>

      <Container
        maxWidth={false}
        component={Box}
        marginTop="60px"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="FINANCIAL EVALUATION"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={handleButtonClick3}
              >
                {showContent3 ? "Hide" : "Show"} Content
              </Button>
            }
          ></CardHeader>
          {showContent3 && (
            <CardContent>
              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    lineHeight: "18px",
                  }}
                >
                  Is the budget realistic and feasible for achieving the
                  proposed activities and goals?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  value={financial}
                  name="example-radio"
                  onChange={(event) => setfinancial(event.target.value)}
                  className={classes.mb0}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="YES"
                    value="yes"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="NO"
                    labelPlacement="end"
                    value="no"
                    classes={{
                      root: classes.formControlCheckboxLabelRoot,
                      label: classes.formControlCheckboxLabelLabel,
                    }}
                  />
                </RadioGroup>
              </Box>

              {financial === "no" && status === "short" &&  (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={SubmitFinancial}
                >
                  Submit
                </Button>
              )}
            </CardContent>
          )}
        </Card>
      </Container>
    </>
  );
};

export default Validation;
