import React from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Pie } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// @material-ui/icons components

// core components
import { chartOptions, parseOptions, createChartExample6 } from "variables/charts";

//import { chartOptions, parseOptions, createChartExample6 } from "variables/charts.js";

import componentStyles from "assets/theme/components/cards/charts/card-affiliate-traffic.js";

const useStyles = makeStyles(componentStyles);

const dummyData = {
  labels: ['Label 1', 'Label 2', 'Label 3'],
  datasets: [{
    data: [30, 30, 40],
    backgroundColor: ['#ff6384', '#36a2eb', '#cc65fe'],
  }],
};

const dummyOptions = {
  responsive: true,
  legend: {
    position: 'top',
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
};

function CardAffiliateTraffic() {

 
  const [chartData, setChartData] = React.useState(dummyData);

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await createChartExample6();
    
      setChartData(data);
    };

    fetchData();
  }, []);

  const [capacity,setCapacity] = React.useState(0)
  const [Knowledge,setKnowledge] = React.useState(0)
  const [policy,setPolicy] = React.useState(0)
  const [data,setData] = React.useState([])

  
  const classes = useStyles();
  const theme = useTheme();
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          title={
            <Box component="span" color={theme.palette.gray[600]}>
              USER RESPONSES
            </Box>
          }
          subheader="REFLECTED AREA"
          classes={{ root: classes.cardHeaderRoot }}
          titleTypographyProps={{
            component: Box,
            variant: "h6",
            letterSpacing: "2px",
            marginBottom: "0!important",
            classes: {
              root: classes.textUppercase,
            },
          }}
          subheaderTypographyProps={{
            component: Box,
            variant: "h3",
            marginBottom: "0!important",
            color: "initial",
          }}
        ></CardHeader>
        <CardContent>
        <Box position="relative" height="350px">
            <Pie
              data={chartData.data}
              options={chartData.options}
              getDatasetAtEvent={(e) => console.log(e)}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardAffiliateTraffic;
