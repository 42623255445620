// core components
// admin pages
// // // // // admin dashboards
//import AlternativeDashboard from "views/admin/Dashboards/AlternativeDashboard.js";
import Dashboard from "views/admin/Dashboards/Dashboard.js";
import UserControl from "views/admin/Tables/UserControlPanel";
// // // // // admin examples
//import Timeline from "views/admin/Examples/Timeline.js";
//import Profile from "views/admin/Examples/Profile.js";
// // // // // admin components
//import Buttons from "views/admin/Components/Buttons.js";
//import Cards from "views/admin/Components/Cards.js";
//import Grid from "views/admin/Components/Grid.js";
//import Notifications from "views/admin/Components/Notifications.js";
//import Icons from "views/admin/Components/Icons.js";
//import Typography from "views/admin/Components/Typography.js";
// // // // // admin forms
//import Elements from "views/admin/Forms/Elements.js";
//import Components from "views/admin/Forms/Components.js";
import Validation from "views/admin/Forms/Validation.js";
import ViewEvaluation from "views/admin/Forms/ViewEvaluation";
import EvaluationForm from "views/admin/Forms/EvaluationForm.js";
import EvaluationForm2 from "views/admin/Forms/EvaluationFormShortList";
// // // // // admin tables
import Sortable from "views/admin/Tables/Sortable.js";
import Africa from "views/admin/Tables/AfricaTable";
import LongListed from "views/admin/Tables/LongListTable";
import EligibleList from "views/admin/Tables/EligibleSortable";
import MyTask from "views/admin/Tables/MyTask";
import ShortListed from "views/admin/Tables/ShortListTable";

// // // // // admin rest
//import Widgets from "views/admin/Widgets.js";
//import Charts from "views/admin/Charts.js";
//import Calendar from "views/admin/Calendar.js";
// rtl pages
//import RTL from "views/rtl/RTL.js";
// auth pages
//import Lock from "views/auth/Lock.js";
import Login from "views/auth/Login.js";
//import Pricing from "views/auth/Pricing.js";
//import Register from "views/auth/Register.js";
// @material-ui/icons components
//import BubbleChart from "@material-ui/icons/BubbleChart";
//import Dns from "@material-ui/icons/Dns";
//import Event from "@material-ui/icons/Event";
//import FlashOn from "@material-ui/icons/FlashOn";
import House from "@material-ui/icons/House";
//import ListAlt from "@material-ui/icons/ListAlt";

//import Palette from "@material-ui/icons/Palette";
import LockOpenIcon from '@material-ui/icons/LockOpen';
//import PieChart from "@material-ui/icons/PieChart";

import Toc from "@material-ui/icons/Toc";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
//import WidgetsIcon from "@material-ui/icons/Widgets";

var routes = [
  {
    collapse: true,
    name: "My Workplace",
    icon: PermIdentityIcon,
    iconColor: "primary",
    state: "personalCollapse",
    views: [
      {
        path: "/My-Task",
        name: "My tasks",
        miniName: "M",
        component: MyTask,
        layout: "/admin",
      },

    ]
  },

  {
    collapse: true,
    name: "Dashboard",
    icon: House,
    iconColor: "primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/Stats",
        name: "Stats",
        miniName: "S",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/Control-Panel",
        name: "User Controls",
        miniName: "U",
        component: UserControl,
        layout: "/admin",
      },
     
    ],
  },
  
  
  {
    collapse: true,
    name: "Applications",
    icon: Toc,
    iconColor: "Default",
    state: "tablesCollapse",
    views: [

      {
        path: "/Africa-Whrds",
        name: "WHRDs in Africa",
        miniName: "A",
        component: Africa,
        layout: "/admin",
      },
      
      {
        path: "/response",
        name: "Pre-Evaluation",
        miniName: "P",
        component: Sortable,
        layout: "/admin",
      },


      {
        path: "/Eligible-responses",
        name: "Eligible-responses",
        miniName: "E",
        component: EligibleList,
        layout: "/admin",
      },

      {
        path: "/long-listed",
        name: "Long-Listed",
        miniName: "L",
        component: LongListed,
        layout: "/admin",
      },

      {
        path: "/short-listed",
        name: "Short-Listed",
        miniName: "S",
        component: ShortListed,
        layout: "/admin",
      }
      
      
      
    ],
  },{
    path: "/",
    icon: LockOpenIcon,
    iconColor: "Default",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
  },
  {
    miniName: "X",
    path: "/User-response",
    component: Validation,
    layout: "/admin",
  },{
    miniName: "X",
    path: "/evaluation-form",
    component: EvaluationForm,
    layout: "/admin",
  },{
    miniName: "X",
    path: "/view-evaluation",
    component: ViewEvaluation,
    layout: "/admin",
  },{
    miniName: "X",
    path: "/evaluation-form-short",
    component: EvaluationForm2,
    layout: "/admin",
  }


  
  
  
  
];
export default routes;
