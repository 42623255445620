const africa = [
    "Angola", "Benin", "Botswana", "Burkina Faso", "Burundi", "Cameroon", "Cape Verde", 
    "Central African Rep", "Chad", "Comoros", "Congo, Dem Rep", "Congo Rep", "Côte d'Ivoire", 
    "Equatorial Guinea", "Eritrea", "Eswatini", "Ethiopia", "Gabon", "Gambia", "Ghana", 
    "Guinea", "Guinea-Bissau", "Kenya", "Lesotho", "Liberia", "Madagascar", "Malawi", "Mali", 
    "Mauritania", "Mauritius", "Mozambique", "Namibia", "Niger", "Nigeria", "Rwanda", 
    "São Tomé & Príncipe", "Senegal", "Seychelles", "Sierra Leone", "Somalia", "South Africa", 
    "South Sudan", "Tanzania", "Togo", "Uganda", "Zambia", "Zimbabwe","Democratic Republic of the Congo","Republic of the Côte d'Ivoire"
];

export default africa;
