import React from "react";
import { useHistory } from "react-router-dom";
//import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import CardContent from "@material-ui/core/CardContent";
//import InputAdornment from "@material-ui/core/InputAdornment";
import CardHeader from "@material-ui/core/CardHeader";
//import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
//import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
//import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

//import Person from "@material-ui/icons/Person";
import Slider from "@material-ui/core/Slider";
import componentStyles from "assets/theme/views/admin/validation.js";
import componentStylesForms from "assets/theme/components/forms.js";
import componentStyles2 from "assets/theme/views/admin/components.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesSwitch from "assets/theme/components/switch.js";

const useStyles2 = makeStyles(componentStyles2);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesSwitch = makeStyles(componentStylesSwitch);

const useStyles = makeStyles(componentStyles);
const useStylesForms = makeStyles(componentStylesForms);

const Validation = () => {
  const history = useHistory();
  const classes = {
    ...useStyles(),
    ...useStylesForms(),
    ...useStyles2(),
    ...useStylesButtons(),
    ...useStylesSwitch(),
  };
  const theme = useTheme();
  const [id, setId] = React.useState("");
  const [question11, setQuestion11] = React.useState("");
  const [question13, setQuestion13] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [summary, setSummary] = React.useState("");
  const [fund, setFund] = React.useState("");
  const [question1, setquestion1] = React.useState("");
  const [question2, setquestion2] = React.useState("");
  const [question3, setquestion3] = React.useState("");
  const [question9, setquestion9] = React.useState("");
  const [question10, setquestion10] = React.useState("");
  const [verdict, setverdict] = React.useState("");

  const [box2, setbox2] = React.useState("");
  const [box3, setbox3] = React.useState("");
  const [box4, setbox4] = React.useState("");
  const [box5, setbox5] = React.useState("");
  const [box6, setbox6] = React.useState("");
  const [box7, setbox7] = React.useState("");
  const [box9, setbox9] = React.useState("");

  const [sliderSingle, setSliderSingle] = React.useState(0);
  const [sliderSingle5, setSliderSingle5] = React.useState(0);
  const [sliderSingle6, setSliderSingle6] = React.useState(0);
  const [sliderSingle7, setSliderSingle7] = React.useState(0);
  const [sliderSingle8, setSliderSingle8] = React.useState(0);

  const Submit = () => {
    if (
      summary === "" ||
      fund === "" ||
      question1 === "" ||
      question2 === "" ||
      question3 === "" ||
      sliderSingle === 0 ||
      sliderSingle5 === 0 ||
      sliderSingle6 === 0 ||
      sliderSingle7 === 0 ||
      sliderSingle8 === 0
    ) {
      alert("Compulsary Fields are not filled");
      return;
    }

    fetch("https://subgrants.safetyforvoices.org:3535/api/updateEvaluation", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({
        token: localStorage.getItem("token"),
        id,
        question10,
        question11,
        question13,
        country,
        summary,
        fund,
        question1,
        question2,
        question3,
        question9,
        box2,
        box3,
        box4,
        box5,
        box6,
        box7,
        box9,
        sliderSingle,
        sliderSingle5,
        sliderSingle6,
        sliderSingle7,
        sliderSingle8,
        question10,
        verdict
    })
})
.then((response) => {
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
})
.then((data) => {
    // Process the response data here
    window.close()
})
.catch((error) => {
    console.error("Error making POST request:", error);
});

   


  };


  React.useEffect(() => {
    fetch("https://subgrants.safetyforvoices.org:3535/api/validate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
      })
      .catch((error) => {
        history.push("/");
        console.error("Error making POST request:", error);
      });

    const storedArray = localStorage.getItem("EvaluationResponse");
    if (storedArray) {
      const parsedArray = JSON.parse(storedArray);
      setId(parsedArray[0]);
      setQuestion11(parsedArray[1]);
      setQuestion13(parsedArray[2]);
      setCountry(parsedArray[3]);
    }


    fetch(
        "https://subgrants.safetyforvoices.org:3535/api/view-evaluationForm",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: localStorage.getItem("selectedResponseID"),
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
         
          setSummary(data[0].summary);
          setFund(data[0].fund)
          setquestion1(data[0].question1)
          setquestion2(data[0].question2)
          setquestion3(data[0].question3)
          setquestion9(data[0].question9)
          setquestion10(data[0].question10)
          setverdict(data[0].verdict)
          setbox2(data[0].box2)
          setbox3(data[0].box3)
          setbox4(data[0].box4)
          setbox5(data[0].box5)
          setbox6(data[0].box6)
          setbox7(data[0].box7)
          setbox9(data[0].box9)
          setSliderSingle(data[0].sliderSingle)
          setSliderSingle5(data[0].sliderSingle5)
          setSliderSingle6(data[0].sliderSingle6)
          setSliderSingle7(data[0].sliderSingle7)
          setSliderSingle8(data[0].sliderSingle8)
          
          // Process the response data here
        })
        .catch((error) => {
          alert("Error making POST request:", error);
        });
  }, []);

  return (
    <>
      <SimpleHeader section="Evaluation" subsection="Forms" />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        {/* Table */}
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="ADDENDUM"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
          ></CardHeader>

          <CardContent>
            <FormGroup>
              <FormLabel>1) Project Summary</FormLabel>
              <Box
                component="small"
                fontSize="80%"
                fontWeight="600"
                marginBottom="10px"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                <br /> Brief project summary
                <br />
                If you suggest rejection, please inform the reason
              </Box>
              <OutlinedInput
                fullWidth
                multiline
                value={summary}
                rows="7"
                onChange={(event) => setSummary(event.target.value)}
              />
            </FormGroup>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                2) Country / Region :{" "}
              </FormLabel>
              <Box
                component="small"
                fontSize="80%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                <br />
                {country}
              </Box>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px", lineHeight:"18px" }}>
                3) Alignment to one or more three programme objectives :
              </FormLabel>
              <Box
                component="small"
                fontSize="80%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                <br />
                {question13}
              </Box>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px", lineHeight:"18px"}}>
                4) Type of grant :
              </FormLabel>
              <Box
                component="small"
                fontSize="80%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                <br />
                {question11}
              </Box>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                5) Has this applicant has received funds already?
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                value={fund}
                
                name="example-radio"
                onChange={(event) => setFund(event.target.value)}
                className={classes.mb0}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Yes"
                  value="Yes"
                
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="No"
                  labelPlacement="end"
                  value="No"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />

                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="I dont know"
                  labelPlacement="end"
                  value="I dont Know"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />
              </RadioGroup>
            </Box>
          </CardContent>
        </Card>

        <Card classes={{ root: classes.cardRoot }}></Card>
      </Container>

      <Container
        maxWidth={false}
        component={Box}
        marginTop="60px"
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="EVALUATION FORM"
            marginTop="100px"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
          ></CardHeader>

          <CardContent>
            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                1) Do you know the references provided?
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                value={question1}
                name="example-radio"
                onChange={(event) => setquestion1(event.target.value)}
                className={classes.mb0}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Yes"
                  value="Yes"
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="No"
                  labelPlacement="end"
                  value="No"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />
              </RadioGroup>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px" , lineHeight:"18px"}}>
                2) Is the project focused in land and environmental issues?
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="example-radio"
                value={question2}
                onChange={(event) => setquestion2(event.target.value)}
                className={classes.mb0}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Yes"
                  value="Yes"
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="No"
                  labelPlacement="end"
                  value="No"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />

                <FormGroup>
                  <OutlinedInput
                    fullWidth
                    value={box2}
                    multiline
                    onChange={(event) => setbox2(event.target.value)}
                    rows="3"
                    placeholder="Comment box"
                  />
                </FormGroup>
              </RadioGroup>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                3) Is the project focused on WHRDs safety?
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                value={question3}
                name="example-radio"
                onChange={(event) => setquestion3(event.target.value)}
                className={classes.mb0}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Yes"
                  value="Yes"
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="No"
                  labelPlacement="end"
                  value="No"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />

                <FormGroup>
                  <OutlinedInput
                    fullWidth
                    value={box3}
                    multiline
                    rows="3"
                    onChange={(event) => setbox3(event.target.value)}
                    placeholder="Comment box"
                  />
                </FormGroup>
              </RadioGroup>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                4) Priority communities and intersectionality
              </FormLabel>

              <Box
                component="small"
                fontSize="80%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                <br />
                Reviewer to reflect on expressed intersectionality, marginalised
                voices ie lgbtqi+, indigenous, black and brown WHRDs, ppl who
                live with disabilities.
                <br />
                Please make sure to consider is implementing and who is the
                target population
                <br /> Insert 1 to 5 button rating
              </Box>

              <RadioGroup
                aria-label="gender"
                name="example-radio"
                defaultValue="2"
                className={classes.mb0}
              >
                <Box display="flex" marginTop="10px" marginBottom="10px">
                  <Slider
                    style={{ minWidth:"250px",maxWidth:"500px", marginRight: "50px" }}
                    min={0}
                    max={5.0}
                    step={1}
                    value={sliderSingle}
                    onChange={(event, value) => setSliderSingle(value)}
                  />
                  <Grid
                    container
                    component={Box}
                    marginTop="1px"
                    marginBottom="5px"
                    marginLeft="10px"
                  >
                    <Grid item xs={6}>
                      <Box
                        component="span"
                        fontSize=".75rem"
                        fontWeight="500"
                        color="#FFFFFF"
                        borderRadius="2px"
                        padding=".4em .8em .3em .85em"
                        className={classes.sliderBadge}
                      >
                        {sliderSingle}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <FormGroup>
                  <OutlinedInput
                    fullWidth
                    value={box4}
                    multiline
                    rows="3"
                    onChange={(event) => setbox4(event.target.value)}
                    placeholder="Comment box"
                  />
                </FormGroup>
              </RadioGroup>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px", lineHeight:"18px" }}>
                5) How effectively was the technological approach formulated and
                integrated into the project?
              </FormLabel>

              <Box
                component="small"
                fontSize="80%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                <br /> Insert 1 to 5 button rating
              </Box>

              <RadioGroup
                aria-label="gender"
                name="example-radio"
                defaultValue="2"
                className={classes.mb0}
              >
                <Box display="flex" marginTop="10px" marginBottom="10px">
                  <Slider
                    style={{ minWidth:"250px",maxWidth:"500px", marginRight: "50px" }}
                    min={0}
                    max={5.0}
                    step={1}
                    value={sliderSingle5}
                    onChange={(event, value) => setSliderSingle5(value)}
                  />
                  <Grid
                    container
                    component={Box}
                    marginTop="1px"
                    marginBottom="5px"
                    marginLeft="10px"
                  >
                    <Grid item xs={6}>
                      <Box
                        component="span"
                        fontSize=".75rem"
                        fontWeight="500"
                        color="#FFFFFF"
                        borderRadius="2px"
                        padding=".4em .8em .3em .85em"
                        className={classes.sliderBadge}
                      >
                        {sliderSingle5}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <FormGroup>
                  <OutlinedInput
                    fullWidth
                    value={box5}
                    multiline
                    rows="3"
                    onChange={(event) => setbox5(event.target.value)}
                    placeholder="Comment box"
                  />
                </FormGroup>
              </RadioGroup>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px", lineHeight:"18px" }}>
                6) Feasibility –To what extent does the application demonstrate
                a clear, feasible, practical and sustainable idea? Are the
                activities proposed achievable? Is the proposed budget
                realistic, efficient and practical ?
              </FormLabel>

              <Box
                component="small"
                fontSize="80%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                <br />
                Please consider if the measures proposed correspond to the risk
                faced by WHRDs
                <br /> Insert 1 to 5 button rating
              </Box>

              <RadioGroup
                aria-label="gender"
                name="example-radio"
                defaultValue="2"
                className={classes.mb0}
              >
                <Box display="flex" marginTop="10px" marginBottom="10px">
                  <Slider
                    style={{ minWidth:"250px",maxWidth:"500px", marginRight: "50px" }}
                    min={0}
                    max={5.0}
                    step={1}
                    value={sliderSingle6}
                    onChange={(event, value) => setSliderSingle6(value)}
                  />
                  <Grid
                    container
                    component={Box}
                    marginTop="1px"
                    marginBottom="5px"
                    marginLeft="10px"
                  >
                    <Grid item xs={6}>
                      <Box
                        component="span"
                        fontSize=".75rem"
                        fontWeight="500"
                        color="#FFFFFF"
                        borderRadius="2px"
                        padding=".4em .8em .3em .85em"
                        className={classes.sliderBadge}
                      >
                        {sliderSingle6}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <FormGroup>
                  <OutlinedInput
                    fullWidth
                    value={box6}
                    multiline
                    onChange={(event) => setbox6(event.target.value)}
                    rows="3"
                    placeholder="Comment box"
                  />
                </FormGroup>
              </RadioGroup>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px", lineHeight:"18px" }}>
                7) Impact – Is this project likely to contribute to improve the
                security of the organisation? What is the expected impact for
                the community?
              </FormLabel>

              <Box
                component="small"
                fontSize="80%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                <br /> Insert 1 to 5 button rating
              </Box>

              <RadioGroup
                aria-label="gender"
                name="example-radio"
                defaultValue="2"
                className={classes.mb0}
              >
                <Box display="flex" marginTop="10px" marginBottom="10px">
                  <Slider
                    style={{ minWidth:"250px",maxWidth:"500px", marginRight: "50px" }}
                    min={0}
                    max={5.0}
                    step={1}
                    value={sliderSingle7}
                    onChange={(event, value) => setSliderSingle7(value)}
                  />
                  <Grid
                    container
                    component={Box}
                    marginTop="1px"
                    marginBottom="5px"
                    marginLeft="10px"
                  >
                    <Grid item xs={6}>
                      <Box
                        component="span"
                        fontSize=".75rem"
                        fontWeight="500"
                        color="#FFFFFF"
                        borderRadius="2px"
                        padding=".4em .8em .3em .85em"
                        className={classes.sliderBadge}
                      >
                        {sliderSingle7}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <FormGroup>
                  <OutlinedInput
                    fullWidth
                    value={box7}
                    multiline
                    onChange={(event) => setbox7(event.target.value)}
                    rows="3"
                    placeholder="Comment box"
                  />
                </FormGroup>
              </RadioGroup>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px", lineHeight:"18px" }}>
                8) Experience - Does the applicant have demonstrated experience
                in implementing similar projects focused on WHRDs protection?
              </FormLabel>

              <Box
                component="small"
                fontSize="80%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                <br /> Insert 1 to 5 button rating
              </Box>

              <RadioGroup
                aria-label="gender"
                name="example-radio"

                defaultValue="2"
                className={classes.mb0}
              >
                <Box display="flex" marginTop="10px" >
                  <Slider
                    style={{ minWidth:"250px",maxWidth:"500px", marginRight: "50px" }}
                    min={0}
                    max={5.0}
                    step={1}
                    value={sliderSingle8}
                    onChange={(event, value) => setSliderSingle8(value)}
                  />
                  <Grid
                    container
                    component={Box}
                    marginTop="1px"
                    marginBottom="5px"
                    marginLeft="10px"
                  >
                    <Grid item xs={6}>
                      <Box
                        component="span"
                        fontSize=".75rem"
                        fontWeight="500"
                        color="#FFFFFF"
                        borderRadius="2px"
                        padding=".4em .8em .3em .85em"
                        className={classes.sliderBadge}
                      >
                        {sliderSingle8}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </RadioGroup>
            </Box>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                9) Do you recommend this project for approval?
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="example-radio"
                value={question9}
                onChange={(event) => setquestion9(event.target.value)}
                className={classes.mb0}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Yes"
                  value="Yes"
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="No"
                
                  labelPlacement="end"
                  value="No"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />

                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Maybe"
                  labelPlacement="end"
                  value="Maybe"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />

                <FormGroup>
                  <OutlinedInput
                    fullWidth
                    multiline
                    value={box9}
                    onChange={(event) => setbox9(event.target.value)}
                    rows="3"
                    placeholder="Comment box"
                  />
                </FormGroup>
              </RadioGroup>
            </Box>

            <FormLabel style={{ marginTop: "2px", marginRight: "4px", lineHeight:"18px"}} >
              10) Please insert any qualitative feedback on the quality of
              application and recommendations for improvement.
            </FormLabel>

            <OutlinedInput
              fullWidth
              multiline
              value={question10}
              rows="7"
              marginBottom="10px"
              onChange={(event) => setquestion10(event.target.value)}
            />

<Box style={{ marginTop: "20px" }}>
              <FormLabel style={{ marginTop: "2px", marginRight: "4px" , lineHeight:"18px" }}>
              Please select the status of this application from the options below
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="example-radio"
                
                onChange={(event) => setverdict(event.target.value)}

                className={classes.mb0}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="Send to short List"
                  value="short"
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label="keep to long list"
                  labelPlacement="end"
                  value="long"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />

               

              
            </RadioGroup>
             
          
            </Box>

            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: "10px" }}
              onClick={Submit}
            >
              SUBMIT
            </Button>



          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Validation;
