import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
//import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
//import FormGroup from "@material-ui/core/FormGroup";
//import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
//import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import componentStyles from "assets/theme/views/admin/validation.js";
import componentStylesForms from "assets/theme/components/forms.js";

const useStyles = makeStyles(componentStyles);
const useStylesForms = makeStyles(componentStylesForms);

const Validation = () => {
  const initialResponse = {
    id: "loading",
    grantnumber: "loading",
    name: "loading",
    phone: "loading",
    email: "loading",
    country: "loading",
    organisation: "loading",
    question1:"loading",
    question2:"loading",
    question3: "loading",
    question7: "loading",
    question8: "loading",
    question9: "loading",
    question10: "loading",
    question11: "loading",
    question12: "loading",
    question13: "loading",
    question14: "loading",
    question15: "loading",
    question16a: "loading",
    question16b: "loading",
    question18: "loading",
    question19: "loading",
    question20: "loading",
    question21: "loading",
    question22: "loading",
    question23a: "loading",
    question23b: "loading",
    question23c: "loading",
    question23d: "loading",
    question23e: "loading",
    question23f: "loading",
    question23g: "loading",
    question23h: "loading",
    filename: "loading",
    filename2: "loading",
    created_at: "loading",
    formatted_created_at: "loading",
  };

  const history = useHistory();
  const classes = { ...useStyles(), ...useStylesForms() };
  const [selectedEval, setSelectedEval] = React.useState(initialResponse);

  const [activities, setactivities] = React.useState([]);
  const theme = useTheme();

  React.useEffect(() => {
    fetch("https://subgrants.safetyforvoices.org:3535/api/validate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
      })
      .catch((error) => {
        history.push("/");
        console.error("Error making POST request:", error);
      });

    fetch(
      "https://subgrants.safetyforvoices.org:3535/api/view-evaluationForm",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: localStorage.getItem("selectedResponseID"),
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
       
        setSelectedEval(data);
        
        
        // Process the response data here
      })
      .catch((error) => {
        alert("Error making POST request:", error);
      });
  }, []);

  const [customStylesFirstNameValue, setCustomStylesFirstNameValue] =
    React.useState("Mark");
  const [customStylesFirstNameValidation, setCustomStylesFirstNameValidation] =
    React.useState(null);
  const [customStylesLastNameValue, setCustomStylesLastNameValue] =
    React.useState("Otto");
  const [customStylesLastNameValidation, setCustomStylesLastNameValidation] =
    React.useState(null);
  const [customStylesUsernameValue, setCustomStylesUsernameValue] =
    React.useState("");
  const [customStylesUsernameValidation, setCustomStylesUsernameValidation] =
    React.useState(null);
  const [customStylesCityValue, setCustomStylesCityValue] = React.useState("");
  const [customStylesCityValidation, setCustomStylesCityValidation] =
    React.useState(null);
  const [customStylesStateValue, setCustomStylesStateValue] =
    React.useState("");
  const [customStylesStateValidation, setCustomStylesStateValidation] =
    React.useState(null);
  const [customStylesZipValue, setCustomStylesZipValue] = React.useState("");
  const [customStylesZipValidation, setCustomStylesZipValidation] =
    React.useState(null);
  const [customStylesCheckboxValue, setCustomStylesCheckboxValue] =
    React.useState(false);
  const [customStylesCheckboxValidation, setCustomStylesCheckboxValidation] =
    React.useState(null);
  return (
    <>
      <SimpleHeader section="Response Evaluation" subsection="Applications" />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        {/* Table */}
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="Response Evaluation"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
          ></CardHeader>
          <CardContent>
            <Grid container>
              <Grid item xs={12} lg={8}>
                <Box
                  component="p"
                  marginBottom="0"
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginTop="0"
                  data-dz-size
                >
                  On This page you can see the evaluation given by the evaluator
                  <br />
                  More functionalities will be added later on...
                  <br />
                  <br />
                </Box>
              </Grid>
            </Grid>

            <Box
              component={Divider}
              marginTop="2rem!important"
              marginBottom="2rem!important"
              style={{ height: "0.1px", backgroundColor: "gray" }}
            />

            <Box>
              <Typography
                variant="h3"
                component="h3"
                className={clsx(classes.mb0, classes.heading)}
              >
               
              </Typography>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  1) Do you know the references provided?
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].question1}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  2) Is the project focused in land and environmental issues?
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].question2}
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].box2}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  3) Is the project focused on WHRDs safety?
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].question3}
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].box3}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  4) Priority communities and intersectionality
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].sliderSingle}
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].box4}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  5) How effectively was the technological approach formulated
                  and integrated into the project?
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].sliderSingle5}
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].question5}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  6) Feasibility –To what extent does the application
                  demonstrate a clear, feasible, practical and sustainable idea?
                  Are the activities proposed achievable? Is the proposed budget
                  realistic, efficient and practical ?
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].sliderSingle6}
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].box6}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  7) Impact – Is this project likely to contribute to improve
                  the security of the organisation? What is the expected impact
                  for the community?
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].sliderSingle7}
                  <br />
                  {selectedEval && selectedEval[0] && selectedEval[0].box7}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  <Box style={{ marginTop: "20px" }}>
                    <FormLabel
                      style={{
                        marginTop: "2px",
                        marginRight: "4px",
                        fontSize: "100%",
                        lineHeight: "20px",
                      }}
                    >
                      8) Experience - Does the applicant have demonstrated
                      experience in implementing similar projects focused on
                      WHRDs protection?
                    </FormLabel>
                    <Box
                      component="small"
                      fontSize="100%"
                      fontWeight="350"
                      color={theme.palette.gray[1000]}
                    >
                      <br />
                      {selectedEval && selectedEval[0] && selectedEval[0].sliderSingle8}
                    </Box>
                  </Box>
                </FormLabel>

                <Box style={{ marginTop: "20px" }}>
                  <FormLabel
                    style={{
                      marginTop: "2px",
                      marginRight: "4px",
                      fontSize: "100%",
                      lineHeight: "20px",
                    }}
                  >
                    9) Do you recommend this project for approval?
                  </FormLabel>
                  <Box
                    component="small"
                    fontSize="100%"
                    fontWeight="350"
                    color={theme.palette.gray[1000]}
                  >
                    <br />
                    {selectedEval && selectedEval[0] && selectedEval[0].question9}
                    <br />
                    {selectedEval && selectedEval[0] && selectedEval[0].box9}
                  </Box>
                </Box>

                <Box style={{ marginTop: "20px" }}>
                  <FormLabel
                    style={{
                      marginTop: "2px",
                      marginRight: "4px",
                      fontSize: "100%",
                      lineHeight: "20px",
                    }}
                  >
                    10) Please insert any qualitative feedback on the quality of
                    application and recommendations for improvement.
                  </FormLabel>
                  <Box
                    component="small"
                    fontSize="100%"
                    fontWeight="350"
                    color={theme.palette.gray[1000]}
                  >
                    <br />
                    {selectedEval && selectedEval[0] && selectedEval[0].question10}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              component={Divider}
              marginTop="2rem!important"
              marginBottom="2rem!important"
            />
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Validation;
