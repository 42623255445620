import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
//import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
//import FormGroup from "@material-ui/core/FormGroup";
//import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
//import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import componentStyles from "assets/theme/views/admin/validation.js";
import componentStylesForms from "assets/theme/components/forms.js";

const useStyles = makeStyles(componentStyles);
const useStylesForms = makeStyles(componentStylesForms);

const Validation = () => {
  const initialResponse = {
    id: "loading",
    grantnumber: "loading",
    name: "loading",
    phone: "loading",
    email: "loading",
    country: "loading",
    organisation: "loading",
    question3: "loading",
    question7: "loading",
    question8: "loading",
    question9: "loading",
    question10: "loading",
    question11: "loading",
    question12: "loading",
    question13: "loading",
    question14: "loading",
    question15: "loading",
    question16a: "loading",
    question16b: "loading",
    question18: "loading",
    question19: "loading",
    question20: "loading",
    question21: "loading",
    question22: "loading",
    question23a: "loading",
    question23b: "loading",
    question23c: "loading",
    question23d: "loading",
    question23e: "loading",
    question23f: "loading",
    question23g: "loading",
    question23h: "loading",
    filename: "loading",
    filename2: "loading",
    created_at: "loading",
    formatted_created_at: "loading",
  };

  const history = useHistory();
  const classes = { ...useStyles(), ...useStylesForms() };
  const [selectedResponse, setSelectedResponse] =
    React.useState(initialResponse);

  const [activities, setactivities] = React.useState([]);
  const theme = useTheme();

  const shortList = () => {
    let EvaluationResponse = [
      selectedResponse.id,
      selectedResponse.question11,
      selectedResponse.question13,
      selectedResponse.country,
      selectedResponse.status,
      selectedResponse.financial,
    ];
    EvaluationResponse = JSON.stringify(EvaluationResponse);
    localStorage.setItem("EvaluationResponse", EvaluationResponse);

    window.open("/#/admin/evaluation-form");
  };

  const shortList2 = () => {
    let EvaluationResponse = [
      selectedResponse.id,
      selectedResponse.question11,
      selectedResponse.question13,
      selectedResponse.country,
      selectedResponse.status,
      selectedResponse.financial,
    ];
    EvaluationResponse = JSON.stringify(EvaluationResponse);
    localStorage.setItem("EvaluationResponse", EvaluationResponse);

    window.open("/#/admin/evaluation-form");
  };

  React.useEffect(() => {
    fetch("https://subgrants.safetyforvoices.org:3535/api/validate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItem("token") }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the response data here
      })
      .catch((error) => {
        history.push("/");
        console.error("Error making POST request:", error);
      });

    const selectedResponseString = localStorage.getItem("selectedResponse");
    const selectedResponse = JSON.parse(selectedResponseString);
    const activities = JSON.parse(selectedResponse.filename2);
    setactivities(activities);
    setSelectedResponse(selectedResponse);
  }, []);

  const [customStylesFirstNameValue, setCustomStylesFirstNameValue] =
    React.useState("Mark");
  const [customStylesFirstNameValidation, setCustomStylesFirstNameValidation] =
    React.useState(null);
  const [customStylesLastNameValue, setCustomStylesLastNameValue] =
    React.useState("Otto");
  const [customStylesLastNameValidation, setCustomStylesLastNameValidation] =
    React.useState(null);
  const [customStylesUsernameValue, setCustomStylesUsernameValue] =
    React.useState("");
  const [customStylesUsernameValidation, setCustomStylesUsernameValidation] =
    React.useState(null);
  const [customStylesCityValue, setCustomStylesCityValue] = React.useState("");
  const [customStylesCityValidation, setCustomStylesCityValidation] =
    React.useState(null);
  const [customStylesStateValue, setCustomStylesStateValue] =
    React.useState("");
  const [customStylesStateValidation, setCustomStylesStateValidation] =
    React.useState(null);
  const [customStylesZipValue, setCustomStylesZipValue] = React.useState("");
  const [customStylesZipValidation, setCustomStylesZipValidation] =
    React.useState(null);
  const [customStylesCheckboxValue, setCustomStylesCheckboxValue] =
    React.useState(false);
  const [customStylesCheckboxValidation, setCustomStylesCheckboxValidation] =
    React.useState(null);
  return (
    <>
      <SimpleHeader section="User Response" subsection="Applications" />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        {/* Table */}
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title="User Response"
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
          ></CardHeader>
          <CardContent>
            <Grid container>
              <Grid item xs={12} lg={8}>
                <Box
                  component="p"
                  marginBottom="0"
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginTop="0"
                  data-dz-size
                >
                  On This page you can see the full response given by the user
                  <br />
                  More functionalities will be added later on...
                  <br />
                  <br />
                </Box>
              </Grid>
            </Grid>

            <Box
              component={Divider}
              marginTop="2rem!important"
              marginBottom="2rem!important"
              style={{ height: "0.1px", backgroundColor: "gray" }}
            />

            <Box>
              <Typography
                variant="h3"
                component="h3"
                className={clsx(classes.mb0, classes.heading)}
              >
                PERSONAL INFORMATION
              </Typography>

              <Box
                display="flex"
                flexDirection="row"
                style={{ marginTop: "20px" }}
              >
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  Name :{" "}
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  {selectedResponse.name}
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                style={{ marginTop: "20px" }}
              >
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  Email :{" "}
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  {selectedResponse.email}
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                style={{ marginTop: "20px" }}
              >
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  Country :{" "}
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  {selectedResponse.country}
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                style={{ marginTop: "20px" }}
              >
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  Phone :{" "}
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  {selectedResponse.phone}
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                style={{ marginTop: "20px" }}
              >
                <FormLabel style={{ marginTop: "2px", marginRight: "4px" }}>
                  Organisation :{" "}
                </FormLabel>
                <Box
                  component="small"
                  fontSize="80%"
                  fontWeight="600"
                  color={theme.palette.gray[600]}
                  className={classes.textUppercase}
                >
                  {selectedResponse.organisation}
                </Box>
              </Box>
            </Box>

            <Box
              component={Divider}
              marginTop="2rem!important"
              marginBottom="2rem!important"
              style={{ height: "0.1px", backgroundColor: "gray" }}
            />

            <Typography
              variant="h3"
              component="h3"
              className={clsx(classes.mb0, classes.heading)}
            >
              PREMILINARY QUESTIONS
            </Typography>

            <Box style={{ marginTop: "20px" }}>
              <FormLabel
                style={{
                  marginTop: "2px",
                  marginRight: "4px",
                  fontSize: "100%",
                  lineHeight: "20px",
                }}
              >
                Please describe how your organisation is structured. Provide a
                gender breakdown of the structure including who makes the
                decisions, who implements them, who is participating and/or
                benefiting.{" "}
              </FormLabel>
              <Box
                component="small"
                fontSize="100%"
                fontWeight="350"
                color={theme.palette.gray[1000]}
              >
                {selectedResponse.question3}
              </Box>
            </Box>

            <Box
              component={Divider}
              marginTop="2rem!important"
              marginBottom="2rem!important"
              style={{ height: "0.1px", backgroundColor: "gray" }}
            />
            <Box>
              <Typography
                variant="h3"
                component="h3"
                className={clsx(classes.mb0, classes.heading)}
              >
                INFORMATION ABOUT THE ORGANISATION
              </Typography>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  5: Please share core details About your work, specifically
                  focusing on work on environmental and land rights defence.
                  Include links to relevant projects / publications if
                  available.{" "}
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question7}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  6: Please describe the people you are working with from an
                  intersectional point of view
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question8}
                </Box>
              </Box>
            </Box>

            <Box
              component={Divider}
              marginTop="2rem!important"
              marginBottom="2rem!important"
            />

            <div display="flex" flexDirection="col">
              <Typography
                variant="h3"
                component="h3"
                className={clsx(classes.mb0, classes.heading)}
              >
                PROJECT APPLICATION
              </Typography>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  7: Project title :
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  {selectedResponse.question10}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  8: Select the area that is most reflected in your grant
                  application :
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  {selectedResponse.question11}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  9: What is the objective of this project :
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question12}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  10: What strategic outcome area does the proposed project
                  relate to. Please select the area that is most closely aligned
                  with your projects’ objectives :
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question13}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  11: Brief project description:
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question14}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  12: Please describe how your project intersects technology
                  with safety of WHRDs working for land and environmental
                  rights:
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question15}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  13. Project duration
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  TO : {selectedResponse.question16a}
                  <br />
                  FROM : {selectedResponse.question16b}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  15. Project budget
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question9}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  16: How will this project contribute to your collective:
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question18}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  17: What expertise do you bring that is relevant to this
                  project:
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question19}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  18: Have you applied to other sources of funding to the same
                  purposes:
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question20}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  19: How did you hear about this opportunity:
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question21}
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <FormLabel
                  style={{
                    marginTop: "2px",
                    marginRight: "4px",
                    fontSize: "100%",
                    lineHeight: "20px",
                  }}
                >
                  20: How do you prefer to be contacted:
                </FormLabel>
                <Box
                  component="small"
                  fontSize="100%"
                  fontWeight="350"
                  color={theme.palette.gray[1000]}
                >
                  <br />
                  {selectedResponse.question22}
                </Box>
              </Box>
            </div>

            <Box
              component={Divider}
              marginTop="2rem!important"
              marginBottom="2rem!important"
              style={{ height: "0.1px", backgroundColor: "gray" }}
            />

            <Typography
              variant="h3"
              component="h3"
              className={clsx(classes.mb0, classes.heading)}
            >
              REFERENCE INFO 1
            </Typography>

            <Box
              display="flex"
              flexDirection="row"
              style={{ marginTop: "20px" }}
            >
              <FormLabel
                style={{
                  marginTop: "0px",
                  marginRight: "4px",
                  fontSize: "100%",
                  lineHeight: "20px",
                }}
              >
                Name :{" "}
              </FormLabel>
              <Box
                component="small"
                fontSize="100%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                {selectedResponse.question23a}
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              style={{ marginTop: "20px" }}
            >
              <FormLabel
                style={{
                  marginTop: "0px",
                  marginRight: "4px",
                  fontSize: "100%",
                  lineHeight: "20px",
                }}
              >
                Email :{" "}
              </FormLabel>
              <Box
                component="small"
                fontSize="100%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                {selectedResponse.question23d}
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              style={{ marginTop: "20px" }}
            >
              <FormLabel
                style={{
                  marginTop: "0px",
                  marginRight: "4px",
                  fontSize: "100%",
                  lineHeight: "20px",
                }}
              >
                Phone :{" "}
              </FormLabel>
              <Box
                component="small"
                fontSize="100%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                {selectedResponse.question23c}
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              style={{ marginTop: "20px" }}
            >
              <FormLabel
                style={{
                  marginTop: "0px",
                  marginRight: "4px",
                  fontSize: "100%",
                  lineHeight: "20px",
                }}
              >
                Organisation :{" "}
              </FormLabel>
              <Box
                component="small"
                fontSize="100%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                {selectedResponse.question23b}
              </Box>
            </Box>

            <Box
              component={Divider}
              marginTop="2rem!important"
              marginBottom="2rem!important"
              style={{ height: "0.1px", backgroundColor: "gray" }}
            />

            <Typography
              variant="h3"
              component="h3"
              className={clsx(classes.mb0, classes.heading)}
            >
              REFERENCE INFO 2
            </Typography>

            <Box
              display="flex"
              flexDirection="row"
              style={{ marginTop: "20px" }}
            >
              <FormLabel
                style={{
                  marginTop: "0px",
                  marginRight: "4px",
                  fontSize: "100%",
                  lineHeight: "20px",
                }}
              >
                Name :{" "}
              </FormLabel>
              <Box
                component="small"
                fontSize="100%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                {selectedResponse.question23e}
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              style={{ marginTop: "20px" }}
            >
              <FormLabel
                style={{
                  marginTop: "0px",
                  marginRight: "4px",
                  fontSize: "100%",
                  lineHeight: "20px",
                }}
              >
                Email :{" "}
              </FormLabel>
              <Box
                component="small"
                fontSize="100%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                {selectedResponse.question23h}
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              style={{ marginTop: "20px" }}
            >
              <FormLabel
                style={{
                  marginTop: "0px",
                  marginRight: "4px",
                  fontSize: "100%",
                  lineHeight: "20px",
                }}
              >
                Phone :{" "}
              </FormLabel>
              <Box
                component="small"
                fontSize="100%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                {selectedResponse.question23g}
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              style={{ marginTop: "20px" }}
            >
              <FormLabel
                style={{
                  marginTop: "0px",
                  marginRight: "4px",
                  fontSize: "100%",
                  lineHeight: "20px",
                }}
              >
                Organisation :{" "}
              </FormLabel>
              <Box
                component="small"
                fontSize="100%"
                fontWeight="600"
                color={theme.palette.gray[600]}
                className={classes.textUppercase}
              >
                {selectedResponse.question23f}
              </Box>
            </Box>

            <Box
              component={Divider}
              marginTop="2rem!important"
              marginBottom="2rem!important"
              style={{ height: "0.1px", backgroundColor: "gray" }}
            />

            <div>
              <Typography
                variant="h3"
                component="h3"
                className={clsx(classes.mb0, classes.heading)}
              >
                ACTIVITY TABLE
              </Typography>

              <ul style={{ listStyleType: "none", padding: 0 }}>
                {activities.map((activity, index) => (
                  <li key={index}>
                    <br />
                    <strong>ACTIVITY {index + 1}</strong>
                    <br />
                    <br />
                    <strong>Activities</strong>: {activity.activity}
                    <br />
                    <strong>Description</strong>: {activity.description}
                    <br />
                    <strong>Outputs:</strong> {activity.outputs}
                    <br />
                    <strong>Deadline:</strong> {activity.deadline}
                    <br />
                    <br />
                  </li>
                ))}
              </ul>
            </div>

            <Box
              component={Divider}
              marginTop="2rem!important"
              marginBottom="2rem!important"
              style={{ height: "0.1px", backgroundColor: "gray" }}
            />

            {selectedResponse.status === "eligible" && (
              <Button color="primary" variant="contained" onClick={shortList}>
                Send to long list
              </Button>
            )}
            {selectedResponse.status === "long" && selectedResponse.assigned_to !== null && (
              <Button color="primary" variant="contained" onClick={shortList2}>
                Send to short list
              </Button>
            )}

            {selectedResponse.status === "short" &&
               (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={shortList2}
                >
                  Add Financial Evaluation
                </Button>
              )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Validation;
