import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/lab components
// @material-ui/icons components

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import UserControl from "views/admin/Dashboards/UserControl.js";
import componentStyles from "assets/theme/views/admin/sortable.js";

const useStyles = makeStyles(componentStyles);

const Sortable = () => {
  const classes = useStyles();
  return (
    <>
      <SimpleHeader section="User-Controls" subsection="Dashboard" />
      {
       
      }
      <Container
        width="10%"
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <UserControl />
        
      </Container>
    </>
  );
};

export default Sortable;
